<template>
  <div class="home" ref="home">
    <!-- 头部 -->
    <div class="header" :style="{ 'background-image':'url('+domain+headerData.image+')'}">
        <HeaderTop :tabNum="tabNum"></HeaderTop>
        <div class="padd-170">
          <div class="recognize-dk">{{headerData.sign}}</div>
          <div class="p1 pul" v-if="headerData.intro">
            <span >{{headerData.intro[0]}}</span>
            <div class="scroll-wrap">
              <el-carousel  direction="vertical" :autoplay="true" indicator-position="none" :interval="2000">
                <el-carousel-item>
                  <p style="color:#2B47F6;">小程序</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">app</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">H5/网站</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">物联网/人工智能</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">大数据/系统集成</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">企业服务</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化电商解决方案</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化场馆解决方案</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化汽服解决方案</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化旅游解决方案</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化养老解决方案</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化医疗解决方案</p>
                </el-carousel-item>
              </el-carousel>
              <!-- <div class="scroll-text">
                  <p>小程序</p>
                  <p>app</p>
                  <p>H5/网站</p>
                  <p>物联网/人工智能</p>
                  <p>大数据/系统集成</p>
                  <p>企业服务</p>
                  <p>小程序</p>
                  <p>app</p>
                  <p>H5/网站</p>
                  <p>物联网/人工智能</p>
                  <p>大数据/系统集成</p>
                  <p>企业服务</p>
              </div> -->
            </div>
          </div>
          <p class="p1" v-if="headerData.intro">{{headerData.intro[1]}}</p>
          <div class="introduce">
            <div class="introduce-tips">{{headerData.labeltitle}}</div>
            <div class="intr">
              <ul class="intr-safe">
                <li v-for="item,index in headerData.label">{{item}}</li>
              </ul>
              <div class="contact" v-if="headerData.btn">
                <!-- <div class="contact-btn1" @click="btn1(0)">{{headerData.btn[0]}}</div> -->
                <el-popover
                  placement="bottom"
                  width="50"
                  trigger="hover">
                  <div class="ewm">
                        <img :src="domain+publicContents.gzh" alt="">
                        <p>{{publicContents.gzhwords}}</p>
                    </div>
                  <el-button class="contact-btn1" slot="reference">{{headerData.btn[0]}}</el-button>
                </el-popover>
                <div class="contact-btns2" @click="btn1(0)" v-if="headerData.btn">
                  <p>{{headerData.btn[1]}}</p>
                  <img src="../assets/img/bottomYjt.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="development">
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/06/wKrMZ24eXQ3V050k1md06/x83ym9xezc1pdgvm/1c42cf05-7bad-4610-9e59-0e9b6510be74.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="3" :num="1"></flip-clock>
              <flip-clock :maxNumber="5" :num="0"></flip-clock>
              <div style="line-height: 53rem;font-size: 40rem;">年</div>
            </div>
            <div>深耕软件开发</div>
          </div>
        </div>
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/08/wKrMZ24eXQ3V050k1md08/yootj0wusuttmrmd/5631b5af-9a03-4a5b-84f9-e224add6d487.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="4" :num="7"></flip-clock>
              *
              <flip-clock :maxNumber="5" :num="2"></flip-clock>
              <flip-clock :maxNumber="3" :num="4"></flip-clock>
              h
            </div>
            <div>及时响应</div>
          </div>
        </div>
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/06/wKrMZ24eXQ3V050k1md06/x83ym9xezc1pdgvm/aef69f0f-185e-4d75-b178-5a7cf04fd980.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="2" :num="1"></flip-clock>
              <flip-clock :maxNumber="3" :num="0"></flip-clock>
              <flip-clock :maxNumber="4" :num="0"></flip-clock>
              <div>%</div>
            </div>
            <div>交付保障</div>
          </div>
        </div>
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/06/wKrMZ24eXQ3V050k1md06/x83ym9xezc1pdgvm/fdedac88-570a-4411-bfc2-216d34d88c15.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="2" :num="3"></flip-clock>
              <flip-clock :maxNumber="3" :num="0"></flip-clock>
              <flip-clock :maxNumber="4" :num="0"></flip-clock>
              <div>+</div>
            </div>
            <div>知名企业的选择</div>
          </div>
        </div>
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/08/wKrMZ24eXQ3V050k1md08/v0h4gijblrtkzvyi/aaed7a0b-4bb8-4424-adba-ffed43fac4f7.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="2" :num="5"></flip-clock>
              <flip-clock :maxNumber="3" :num="0"></flip-clock>
              <flip-clock :maxNumber="4" :num="0"></flip-clock>
              <div>+</div>
            </div>
            <div>成功案例</div>
          </div>
        </div>
        
      </div>
    </div>
    <!-- 局部左右边距320版新 -->
    <div class="box boxhome" id="wedo1">
      <!-- 我们能为您做什么？ -->
      <div class="wedo" >
        我们能为您做什么？
      </div>
      <!-- style="height: 936rem;" -->
      <div class="box-item" >
        <ul class="ul2" v-if="homeData[0]">
          <li :class="customized>=500?'li'+index:''" v-for="item,index in homeData[0].content" @click="softwareUrl(item,1)">
            <div class="ul2Li-top">
              <img :src="domain+item.image" alt="">
              <div class="ul2Li-name">
                <p class="ul2Li-p1">{{item.name}}</p>
                <p class="ul2Li-p2">{{item.intro}}</p>
              </div>
            </div>
            <div class="ul2Li-bottom">
              <p class="ul2Li-p3">{{item.words1}}</p>
              <p class="ul2Li-p4">{{item.words2}}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="csa">
      <!-- 解决方案 -->
      <div class="productdisplay" v-show="false" v-if="homeData[1]">
        <div class="box-item">
          <div class="wedo"style="margin-top:0;color:#FFFFFF;">解决方案</div>
          <div class="proditem">
            <div class="proditemafter">
              <div  v-for="item,index in homeData[1].content" :key="index">
                <div :class="[isDuoka==index?'list-title list-title2':'list-title']" @mouseenter="solve(index)">
                  <p>{{item.name}}</p>
                </div>
                <el-collapse-transition v-if="false">
                  <!-- v-show="isDuoka" -->
                  <div :class="[isDuoka==index?'':'list-cons-max']" v-for="item,index in homeData[1].content" :key="index">
                      <div class="list-const"  @click="softwareUrl(item,2)">
                        <div class="list-const-left">
                          <p class="list-const-title">{{item.title}}</p>
                          <p class="list-const-info" v-for="item2,index2 in item.intro" :key="index2">
                            {{item2}}
                          </p>
                          <!-- <div class="list-const-boxinfo22">
                            <div class="list-const-info22" v-for="item3,index3 in item.label" :key="index3">{{item3}}</div>
                          </div> -->
                          <div class="list-const-btns" @click.stop="btn1">
                            <div class="list-const-btn1">
                              <p>预约演示</p>
                              <img src="../assets/img/yjt2.png" alt="">
                            </div>
                            <!-- <div class="list-const-btn2">
                              <p>查看服务细节</p>
                              <img src="../assets/img/bottomYjt.png" alt="">
                            </div> -->
                          </div>
                        </div>
                        <div class="list-const-right2">
                          <div class="list-const-item" v-for="item3,index3 in item.did" :key="index3">
                            <!-- <img :src="domain+item3.image" alt=""> -->
                            <div>
                              <p class="list-const-name">{{item3.name}}</p>
                              <p class="list-const-name2">{{item3.intro}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
            <div :class="[isDuoka==index?'':'list-cons-max']" v-for="item,index in homeData[1].content" :key="index">
                <div class="list-const"  @click="softwareUrl(item,2)" @wheel="handleWheel" @mouseenter="checkScroll" @mouseleave="checkScroll2">
                  <div class="list-const-left">
                    <p class="list-const-title">{{item.title}}</p>
                    <p class="list-const-info" v-for="item2,index2 in item.intro" :key="index2">
                      {{item2}}
                    </p>
                    <!-- <div class="list-const-boxinfo22">
                      <div class="list-const-info22" v-for="item3,index3 in item.label" :key="index3">{{item3}}</div>
                    </div> -->
                    <div class="list-const-btns">
                      <div class="list-const-btn1" @click.stop="btn1">
                        <p>预约演示</p>
                        <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/08/wKrMZ24eXQ3V050k1md08/v0h4gijblrtkzvyi/b12e831e-73a6-42d9-9828-6093937d6c4a.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                      </div>
                      <!-- <div class="list-const-btn2">
                        <p>查看服务细节</p>
                        <img src="../assets/img/bottomYjt.png" alt="">
                      </div> -->
                    </div>
                  </div>
                  <div class="list-const-right2">
                    <div class="list-const-item2" v-for="item3,index3 in item.did" :key="index3">
                      <!-- <img :src="domain+item3.image" alt=""> -->
                      <div>
                        <p class="list-const-name3">{{item3.name}}</p>
                        <p class="list-const-name4">{{item3.intro}}</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 解决方案 -->
      <div class="Solution" id="wedo4" v-if="homeData[1]">
          <div class="wedo"style="margin-top:0rem;padding-top: 138rem;color:#000000;">解决方案</div>
          <div class="SoluTab">
            <div :class="[isDuoka==index?'SoluTabItem SoluTabItem2':'SoluTabItem']" v-for="item,index in homeData[1].content" :key="index" @mouseenter="solve(index)">
              <img :src="domain+item.selectimage" alt="" v-if="isDuoka==index">
              <img :src="domain+item.image" v-else>
              <p v-if="isDuoka==index" style="color:#2B47F6;">{{item.name}}</p>
              <p v-else>{{item.name}}</p>
            </div>
          </div>
          <div class="box-item SoluBox" :style="{backgroundImage:'url('+domain+homeData[1].content[isDuoka].banner+')'}">
            <div class="">
              <div class="SoluTitle">{{homeData[1].content[isDuoka].title}}</div>
              <div class="SoluTitle2" v-if="homeData[1].content[isDuoka].intro.length==1">{{homeData[1].content[isDuoka].intro[0]}}</div>
              <div class="SoluTitle2" v-else v-for="a,b in homeData[1].content[isDuoka].intro" :key="b">{{a}}<br></div>
              <div class="SoluIcon" v-if="homeData[1].content[isDuoka].images.length!=0">
                <div class="SoluIconItem" v-for="x,y in homeData[1].content[isDuoka].images" :key="y">
                  <img :src="domain+x" alt="">
                </div>
              </div>
              <div class="list-const-btns" v-if="homeData[1].content[isDuoka].images.length!=0">
                <div class="list-const-btn1" @click.stop="btn1">
                  <p>预约演示</p>
                  <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/08/wKrMZ24eXQ3V050k1md08/v0h4gijblrtkzvyi/b12e831e-73a6-42d9-9828-6093937d6c4a.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                </div>
              </div>
              <div class="list-const-btns" style="margin-top:120rem;" v-else>
                <div class="list-const-btn1" @click.stop="btn1">
                  <p>预约演示</p>
                  <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/08/wKrMZ24eXQ3V050k1md08/v0h4gijblrtkzvyi/b12e831e-73a6-42d9-9828-6093937d6c4a.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                </div>
              </div>
            </div>
            <div class="SoluBoxItem">
              <div class="SoluBoxTab">
                <div :class="[isDuoka2==y?'SoluBoxTabItem SoluBoxTabItem2':'SoluBoxTabItem']" v-for="x,y in homeData[1].content[isDuoka].did" :key="y" @click="solve2(y)">{{x.name}}</div>
              </div>
              <div class="SoluBoxCon" v-if="isDuoka2==y" v-for="x,y in homeData[1].content[isDuoka].did" :key="y">
                <div class="SoluBoxConTitle">{{x.name}}</div>
                <div class="SoluBoxConInfo">{{x.intro}}</div>
                  <img :src="domain+x.image">
              </div>
            </div>
          </div>
      </div>
      <!-- 行业软件产品服务 -->
      <div class="">
        <div class="wedo"style="margin-top:70rem;color:#000000;">行业软件产品服务</div>
        <img class="yu" src="../assets/img/yu.png" alt="">
      </div>
      <!-- 服务优势 -->
      <div class="advantages">
        <div class="wedo" style="margin-bottom: 60rem;margin-top: 0rem;">服务优势</div>
        <!-- <div class="prompt" v-if="homeData[2]" style="margin-top: 10rem;">{{homeData[2].title[0].substring(0,homeData[2].title[0].indexOf("#"))}}</div>
        <div class="prompt" v-if="homeData[2]">
          {{homeData[2].title[0].substring(homeData[2].title[0].indexOf("#")+1,Number(homeData[2].title[1])+1)}}<span style="color:#FF4EB5;">{{homeData[2].title[0].substring(Number(homeData[2].title[1])+1)}}</span>
        </div> -->
        <div class="service">
          <div class="service-list" v-if="homeData[2]">
            <div class="service-item-left">
              <div class="service-item" style="margin-left: 60rem;">
                <div>
                  <p class="service-p1">{{homeData[2].content[0].name}}</p>
                  <p class="service-p2">{{homeData[2].content[0].intro}}</p>
                </div>
                <img style="margin-left: 30rem;" :src="domain+homeData[2].content[0].image" alt="">
              </div>
              <div class="service-item" style="margin-left: 60rem;">
                <div>
                  <p class="service-p1">{{homeData[2].content[1].name}}</p>
                  <p class="service-p2">{{homeData[2].content[1].intro}}</p>
                </div>
                <img style="margin-left: 30rem;" :src="domain+homeData[2].content[1].image" alt="">
              </div>
              <div class="service-item" style="margin-left: 60rem;">
                <div>
                  <p class="service-p1">{{homeData[2].content[2].name}}</p>
                  <p class="service-p2">{{homeData[2].content[2].intro}}</p>
                </div>
                <img style="margin-left: 30rem;" :src="domain+homeData[2].content[2].image" alt="">
              </div>
            </div>
            <div class="service-item-center">
              <div >
                <p>专业技术</p>
                <p>助力企业</p>
              </div>
            </div>
            <div class="service-item-right">
              <div class="service-item service-item2" style="margin-left: 60rem;">
                <img style="margin-left: 30rem;margin-right: 30rem;" :src="domain+homeData[2].content[3].image" alt="">
                <div>
                  <p class="service-p1">{{homeData[2].content[3].name}}</p>
                  <p class="service-p2">{{homeData[2].content[3].intro}}</p>
                </div>
              </div>
              <div class="service-item service-item2" style="margin-left: 60rem;">
                <img style="margin-left: 30rem;margin-right: 30rem;" :src="domain+homeData[2].content[4].image" alt="">
                <div>
                  <p class="service-p1">{{homeData[2].content[4].name}}</p>
                  <p class="service-p2">{{homeData[2].content[4].intro}}</p>
                </div>
              </div>
              <div class="service-item service-item2" style="margin-left: 60rem;">
                <img style="margin-left: 30rem;margin-right: 30rem;" :src="domain+homeData[2].content[5].image" alt="">
                <div>
                  <p class="service-p1">{{homeData[2].content[5].name}}</p>
                  <p class="service-p2">{{homeData[2].content[5].intro}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 开发流程 -->
      <div class="deve">
        <div class="wedo" style="margin-bottom: 60rem;">开发流程</div>
        <div class="flowPath" v-if="homeData[3]">
          <el-progress :percentage="percentage" color="#2B47F6" define-back-color="#E6E6E6" :show-text="false"></el-progress>
          <div class="flowPathList">
            <div :class="[homeData[3].content.length==index+1?'flowPathItem2':'flowPathItem']" v-for="item,index in homeData[3].content" :key="index">
              <img :src="domain+item.image" alt="">
              <p :class="[index%2==0?'flowPathItem-p1':'flowPathItem-p1 flowPathItem-p1-2']">{{item.name}}</p>
              <p class="flowPathItem-p2">{{item.intro}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 客户案例 -->
      <div class="banner" id="wedo2">
        <div class="wedo" style="margin-top:0;">
          客户案例
        </div>
        <div style="margin-top: 64rem;" v-if="homeData[4]">
          <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
            <el-tab-pane :label="item.name" :name="item.name" v-for="item,index in homeData[4].content.caseclass" :key="index">
              <div class="tab-list">
                <div class="tab-item" @click="caseUrl(item)"  v-if="b<=7" v-for="a,b in homeData[4].content.case" :key="b">
                  <img :src="domain+a.image" alt="">
                  <div class="tab-item-title">
                    <p class="aname">{{a.name}}</p>
                    <p class="aintro">{{a.intro}}</p>
                  </div>
                  <div class="tab-item-smegma" @mouseenter="mouseenter(a,b)" @mouseleave="mouseleave(a,b)">
                      <!-- <img src="https://cdn3.codesign.qq.com/screen-slices/2023/04/23/wKrMZ23eXQ3V040k1md23/jeebjmwgi4tbdcvb/888202dc-29a0-4379-ac58-ee65aa87df56.png?imageMogr2/thumbnail/700x650/interlace/1" alt=""> -->
                      <!-- <p class="tab-item-p1">锦鲤拿趣</p>
                      <p class="tab-item-p2">抽盒一番赏商城</p> -->
                      <transition name="el-fade-in">
                        <div v-if="ckxq==b" class="tab-item-smegma2" >
                          <p class="tab-item-smegma2-p1">{{a.desc.substring(0,a.desc.indexOf("#"))}}</p>
                          <p class="tab-item-smegma2-p2">{{a.desc.substring(a.desc.indexOf("#")+1)}}</p>
                          <div class="ckxq">查看详情</div>
                        </div>
                      </transition>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        
        <!-- <p class="prompt-p1" v-if="homeData[6]">{{homeData[6].title[0]}}</p> -->
        <div class="box-item" style="margin-top: 83rem;">
          <div class="scoll_x" v-if="homeData[6]">
            <!-- <div class="scoll-item" v-for="item,index in homeData[6].content">
              <img :src="domain+item.image" alt="">
            </div> -->
            <ul class="donghua">
              <li class="scoll-item" v-for="item,index in hzhbImg1"><img :src="domain+item" alt=""></li>
            </ul>
            <ul class="donghua" style="top: 88rem;">
              <li class="scoll-item" v-for="item,index in hzhbImg2"><img :src="domain+item" alt=""></li>
            </ul>
            <img class="scoll-rightImg" src="../assets/img/left.png" >
            <img class="scoll-leftImg" src="../assets/img/right.png" >
          </div>
          </div>
      </div>
      <!-- 新闻资讯 -->
      <div class="p-40" id="wedo3">
        <div class="wedo" style="margin-top:83rem;">
          新闻资讯
        </div>
        <div class="news-list1" v-if="homeData[7]">
          <div class="news-item1 b1" v-for="item,index in homeData[7].content" :key="index" @click="Morenews(item.id)">
            <div class="news-maxImgs">
              <img class="news-maxImg1" :src="domain+item.image" alt="">
            </div>
            <div class="news_const1">
              <p class="news_const-p1-1">{{item.name}}</p>
              <p class="news_const-p2-1">{{item.intro}}</p>
              <p class="news_const-p3-1">{{item.showtime.substring(5,10)}}</p>
              <div class="news_const-btm-1">
                <p class="news_const-p4-1">{{item.showtime.substring(0,4)}}</p>
                <img src="../assets/img/yjt_h.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="news-btn" @click="Moreinformation">
          <p>更多资讯</p>
          <img src="../assets/img/bottomYjt.png" alt="">
        </div>
      </div>
      </div>
      <div style="height:90rem; background:#F4F5F8;"></div>
    </div>
    <!-- <div @click="checkIsAchiveBottom" style="height:50px;width:50px;background:red;position: absolute;top:0px;"></div> -->
    <FootBottom></FootBottom>
    <FootBottom2 :tabNum="tabNum"></FootBottom2>
  </div>
</template>

<script>
// @ is an alias to /src
import FlipClock from '@/components/FlipClock.vue';
import HeaderTop from '@/components/HeaderTop.vue'
import FootBottom from '@/components/FootBottom.vue'
import FootBottom2 from '@/components/FootBottom2.vue'
import { getAdvByType,getIndexData,getCommonData } from '@/assets/js/store.js'

export default {
  name: 'Home',
  data() {
			return {
        // 域名
        domain:'',
        // 顶部tab选项值
        tabNum:0,
        tabList2:[1,2,3,4,5,6,7,8],
        // 头部数据
        headerData:{},
        // 首页数据
        homeData:{},
        // 公司产品展示显示隐藏
        isDuoka:0,
        isDuoka2:0,
        // 进度条
        percentage:0,
        // tab选项卡的默认选型
        activeName: '',
        ckxq:1000,
        // 合作伙伴的图片
        hzhbImg1:[],
        hzhbImg2:[],
        // 完成项目
        aaa:0,
        bbb:0,
        ccc:0,
        // 公共内容
        publicContents:{},
        // 定制开发内容过渡
        customized:0,
			}
	},
  components: {
    HeaderTop,
    FootBottom,
    FootBottom2,
    FlipClock
  },
  created(){
      

},
  beforeDestroy() {
    // 组件销毁前移除事件监听器
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.timer);
    clearInterval(this.timer2);
    this.checkScroll2()
  },
  mounted(){
    console.log(this.$route.path);
    // 添加滚动事件监听器
    window.addEventListener('scroll', this.handleScroll)
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      if(this.$route.path != '/wap/ydIndex'){
        this.$router.replace('/wap/ydIndex')
      }
    } else {
      if(this.$route.path != '/'){
        this.$router.replace('/')
      }
    }
    this.getgetAdvByType()
    this.getgetIndexData()
    this.getgetCommonData()
    // this.checkIsAchiveBottom();
    setInterval(()=>{
        this.percentage++
        if(this.percentage==100){
          this.percentage = 0
        }
    },100)
    
    this.timer = setInterval(()=>{
      this.num1++
      this.num2++
      this.num3++
      this.num4++
      this.num5++
      this.num6++
      this.num7++
      this.num8++
      this.num9++
      this.num10++
      this.num11++
      this.num12++
      this.num13++
      this.num14++
      if(this.num1 == 9){
        this.num1 = 0
      }
      if(this.num2 == 9){
        this.num2 = 0
      }
      if(this.num3 == 9){
        this.num3 = 0
      }
      if(this.num4 == 9){
        this.num4 = 0
      }
      if(this.num5 == 9){
        this.num5 = 0
      }
      if(this.num6 == 9){
        this.num6 = 0
      }
      if(this.num7 == 9){
        this.num7 = 0
      }
      if(this.num8 == 9){
        this.num8 = 0
      }
      if(this.num == 9){
        this.num = 0
      }
      if(this.num9 == 9){
        this.num9 = 0
      }
      if(this.num10 == 9){
        this.num10 = 0
      }
      if(this.num11 == 9){
        this.num11 = 0
      }
      if(this.num12 == 9){
        this.num12 = 0
      }
      if(this.num13 == 9){
        this.num13 = 0
      }
      if(this.num14 == 9){
        this.num14 = 0
      }
    },100)
    this.timer2 = setTimeout(()=>{
      clearInterval(this.timer);
      clearInterval(this.timer2);
      this.num1 = 1
      this.num2 = 0
      this.num3 = 7
      this.num4 = 2
      this.num5 = 4
      this.num6 = 1
      this.num7 = 0
      this.num8 = 0
      this.num9 = 3
      this.num10 = 0
      this.num11 = 0
      this.num12 = 5
      this.num13 = 0
      this.num14 = 0
    },2000)
  },
  methods: {
    checkScroll(){
      window.addEventListener('wheel', this.preventScroll, { passive: false });
    },
    checkScroll2(){
      window.removeEventListener('wheel', this.preventScroll);
    },
    preventScroll(event) {
      event.preventDefault(); // 阻止滚轮事件的默认行为
    },
    handleWheel(event){
      if (event.deltaY < 0) {
        if(this.isDuoka+1 > 1){
          this.isDuoka--
          this.checkScroll()
        }else if(this.isDuoka == 0){
          // this.isDuoka = this.homeData[1].content.length-1
          this.checkScroll2()
        }
        // console.log('向下',this.isDuoka);
      } else if (event.deltaY > 0) {
        if(this.isDuoka+1 < this.homeData[1].content.length){
          this.isDuoka++
          this.checkScroll()
        }else if(this.isDuoka+1 == this.homeData[1].content.length){
          // this.isDuoka = 0
          this.checkScroll2()
        }
        // console.log('向上',this.isDuoka,this.homeData[1].content.length);
      }
    },
    handleScroll() {
      // 处理滚动事件
      if(window.scrollY>=500){
        this.customized = window.scrollY
      }else{
        
      }
    },
    async getgetCommonData(){
            const res = await getCommonData()
            if(res.data.code == 1){
                this.publicContents = res.data.data
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
    // 判断是否是手机端，如果是，返回true
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    // 根据类型获取广告（顶部Banner）
    async getgetAdvByType(){
      const res = await getAdvByType({type:1})
      if(res.data.code == 1){
        this.domain = res.data.data.headpath
        this.headerData = res.data.data
        // let pageHeight = window.getComputedStyle(this.$refs.home).height; 
        // console.log('123456',pageHeight);
      }else{
        this.$message({
          showClose: true,
          message: res.data.msg,
          duration:3000,
          type: 'error'
        });
      }
    },
    // 获取首页数据
    async getgetIndexData(cid){
      const res = await getIndexData({cid:cid})
      if(res.data.code == 1){
        this.homeData = res.data.data
        if(cid==undefined){
          this.activeName = this.homeData[4].content.caseclass[0].name
        }else{
          this.activeName = this.homeData[4].content.caseclass[cid].name
          console.log(this.activeName,cid);
        }
        this.hzhbImg1=[]
        this.hzhbImg2=[]
        // res.data.data[6].content.forEach((item,index) => {
        //   if(index<=12){
        //     this.hzhbImg1.push(item.image)
        //   }else{
        //     this.hzhbImg2.push(item.image)
        //   }
        // });
        let num = res.data.data[6].content.length
        if(num%2==0){
          num = num/2
        }else{
          num = num/2
          num = Math.ceil(num)
        }
        res.data.data[6].content.forEach((item,index) => {
          if(index<=num){
            this.hzhbImg1.push(item.image)
          }else{
            this.hzhbImg2.push(item.image)
          }
        });
      }else{
        this.$message({
          showClose: true,
          message: res.data.msg,
          duration:3000,
          type: 'error'
        });
      }
    },
    // 公司产品展示的列表显示与隐藏
    isDuokas(index){
      this.isDuoka = -1
    },
    solve(index){
      this.isDuoka = index
      this.isDuoka2 = 0
    },
    solve2(y){
      this.isDuoka2 = y
    },
    isDuokas2(index){
      this.isDuoka = index
      console.log(this.isDuoka);
    },
    // 公司案例展示的阴影
    mouseenter(a,b){
      // this.ckxq = b
    },
    mouseleave(a,b){
      // this.ckxq = 1000
    },
    btn1(x){
      document.getElementsByClassName("embed-icon-default")[0].click()
    },
    // 软件产品跳转（小程序开发等）
    softwareUrl(item,x){
      // console.log(item,x);
      this.$router.push({path:'/software',query: {id: item.id,type: x,names:item.name}})
    },
    // 公司案例展示切换
    handleClick(tab, event) {
      // console.log(tab);
      this.getgetIndexData(this.homeData[4].content.caseclass[Number(tab.index)].id)
      
      // console.log(tab, event);
    },
    // 公司案例展示跳转
    caseUrl(item){
      // this.$router.push({path:'/success',query: {id:item.id}})
    },
    // 资讯详情跳转1
    Moreinformation(){
      this.$router.push('/information')
    },
    // 资讯详情跳转2
    Morenews(id){
      this.$router.push({path:'/information',query: {id:id}})
    }
  }
}
</script>
<style lang="less" scoped>
@import '../assets/css/home.css';

/deep/.el-tabs__item{
    font-weight: 600;
    font-size: 20px;
    height: 60px;
}
/deep/.el-tabs__item:hover{
  color: #2B47F6 !important;
}
/deep/.el-tabs__item.is-active{
  color: #2B47F6 !important;
}
/deep/.el-tabs__active-bar{
  // position: absolute;
  //   bottom: 0;
  //   left: 0;
    height: 2px;
    background-color: #2B47F6;
  //   z-index: 1;
}
// /deep/.is-active{
  
//   // color: #2B47F6 !important;
// }
/deep/.el-tabs__nav-wrap::after{
  background: none;
}
// /deep/.el-tabs__active-bar{
//   // background: #2B47F6;
// }
/deep/.el-carousel__container{
    height: 140rem;
}
// /deep/.is-active{
//   // height: 110rem;
//   // color: #FFFFFF !important;
// }
/deep/.el-tabs__header {
  width: 60%;
  margin: auto !important;
}
</style>