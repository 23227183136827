<!-- yd_software 软件产品 -->
<template>
    <div>
        <ydHeaderTop></ydHeaderTop>
        <div class="yd_about_header">
            <p class="ydHeader_p3" style="margin-bottom: 0.07rem;">
                小程序开发
            </p>
            <p class="ydHeader_p3">
                首选多咖科技
            </p>
            <p class="ydHeader_p4">
                共享亿万级移动流量红利！
            </p>
        </div>
        <div class="ydScorBox">
            <p class="ydScorBox_p1">小程序开发，抓住亿万级移动流量红利！</p>
            <p class="ydScorBox_p2">提供完整的解决方案，助力精准高效获取用户</p>
            <div class="ydScorBoxMod">
                <div class="ydScorBoxItem">
                    <img src="../assets/img/kf.png" alt="">
                    <p class="ydScorBoxItemP1">开发文档</p>
                    <p class="ydScorBoxItemP2">介绍小程序的开发框架、基础组件及API等</p>
                </div>
                <div class="ydScorBoxItem">
                    <img src="../assets/img/kfz.png" alt="">
                    <p class="ydScorBoxItemP1">开发者工具</p>
                    <p class="ydScorBoxItemP2">丰富的框架、组件、API、开发工具支持小程序开发</p>
                </div>
                <div class="ydScorBoxItem" style="margin-top: 0.14rem;">
                    <img src="../assets/img/sj.png" alt="">
                    <p class="ydScorBoxItemP1">设计指南</p>
                    <p class="ydScorBoxItemP2">标准的设计原则、规范、组件化资源支持小 程序设计</p>
                </div>
                <div class="ydScorBoxItem" style="margin-top: 0.14rem;">
                    <img src="../assets/img/yy.png" alt="">
                    <p class="ydScorBoxItemP1">运营接入</p>
                    <p class="ydScorBoxItemP2">标准化规范运营、多渠道多方式接入</p>
                </div>
            </div>
        </div>
        <!-- 开发范围 -->
        <div class="ydScorBox" style="background: #FAFBFF;">
            <p class="ydScorBox_p1" style="font-size: 0.2rem;">开发范围</p>
            <p class="ydScorBox_p2">采用前沿全平台适配框架，开发一套，兼容多平台小程序</p>
            <div class="ydScorBoxFun">
                <div class="ydScorBoxitem2">
                    <img class="maximg" src="../assets/yd_img/wx.png" alt="">
                    <img class="minimg" src="../assets/yd_img/wx1.png" alt="">
                    <p>微信小程序</p>
                </div>
                <div class="ydScorBoxitem2">
                    <img class="maximg" src="../assets/yd_img/zfb.png" alt="">
                    <img class="minimg" src="../assets/yd_img/zfb1.png" alt="">
                    <p>支付宝小程序</p>
                </div>
                <div class="ydScorBoxitem2" style="margin-top: 0.1rem;">
                    <img class="maximg" src="../assets/yd_img/bd.png" alt="">
                    <img class="minimg" src="../assets/yd_img/bd1.png" alt="">
                    <p>百度小程序</p>
                </div>
                <div class="ydScorBoxitem2" style="margin-top: 0.1rem;">
                    <img class="maximg" src="../assets/yd_img/dy.png" alt="">
                    <img class="minimg" src="../assets/yd_img/dy1.png" alt="">
                    <p>抖音小程序</p>
                </div>
            </div>
            <div class="ydScorBoxBtn">
                <p>预约演示</p>
                <img src="../assets/yd_img/yjt_lan.png" alt="">
            </div>
        </div>
        <!-- 解决方案 -->
        <div class="ydScorBox" style="padding-bottom:0;background: #FFFFFF;">
            <p class="ydScorBox_p1" style="font-size: 0.2rem;">解决方案</p>
            <p class="ydScorBox_p2">持续探索，具有前沿领域技术服务经验,并形成了稳健解决方案</p>
            <div class="ydScorBoxMax">
                <div style="background: url(https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/be68bf02-e5ee-4870-8b56-1336f25ef870.png?imageMogr2/thumbnail/700x650/interlace/1https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/be68bf02-e5ee-4870-8b56-1336f25ef870.png?imageMogr2/thumbnail/700x650/interlace/1);">
                    <p>电商零售</p>
                </div>
                <div style="background: url(https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/208858d6-186b-4050-9922-1259e153592a.png?imageMogr2/thumbnail/700x650/interlace/1);">
                    <p>大健康医疗</p>
                </div>
            </div>
            <div class="ydScorBoxMin">
                <div style="background: url(https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/ce9168e7-1926-49b2-82db-db1105ded02b.png?imageMogr2/thumbnail/700x650/interlace/1);">
                    <p>旅游行业平台</p>
                </div>
                <div style="background: url(https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/6c5db0ef-e3e4-440b-bf26-2b7216ef34e7.png?imageMogr2/thumbnail/700x650/interlace/1);">
                    <p>园区综合体</p>
                </div>
                <div style="background: url(https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/acb7ec42-95b5-4b82-a163-806a0b28823a.png?imageMogr2/thumbnail/700x650/interlace/1);">
                    <p>汽车后市场</p>
                </div>
            </div>
        </div>
        <!-- 服务优势 -->
        <div class="padd-15 Service">
            <div class="indexTitle">服务优势</div>
            <ul class="dkkj_ul" v-if="homeData[2]">
                <li class="dkkj_li" v-for="item,index in homeData[2].content" :key="index">
                    <img class="dkkj_img" :src="domain+item.image" alt="">
                    <div class="dkkj_top">
                        <p class="dkkj_p1">{{item.name}}</p>
                        <p class="dkkj_p2">{{item.intro}}</p>
                    </div>
                </li>
            </ul>
            <div style="height:0.5rem;"></div>
        </div>
        <div class="back_box">
            <!-- 开发流程 -->
            <div class="process">
                <div class="indexTitle" style="color: #FFFFFF;">开发流程</div>
                <div class="processBox">
                    <div class="process_img">
                        <div class="process_lan"></div>
                        <div class="process_img_title" v-if="homeData[3]">
                            <div class="_img_title" v-for="item,index in homeData[3].content" :key="index">
                                <img :src="domain+item.image" alt="">
                                <div>
                                    <p>{{item.name}}</p>
                                    <p class="process_p2">{{item.intro}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height:0.2rem;"></div>
            </div>
            <!-- 客户案例  -->
            <div class="case_title">
                <div class="indexTitle" style="font-size: 600;">客户案例</div>
            </div>
            <div class="case_tabs" v-if="homeData[4]">
                <div class="case_tab2" :style="{'width':homeData[4].content.caseclass.length*(1.1)+'rem'}">
                    <div :class="tabIndex==index?'case_tab_item1 case_tab_item3':'case_tab_item1'" @click="tabIndex1(item,index)" v-for="item,index in homeData[4].content.caseclass" :key="index">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="case_imgs" v-if="homeData[4]">
                <div class="case_imgs_box" :style="{'width':9.5+'rem'}">
                    <div class="img" v-for="a,b in homeData[4].content.case" :key="b" :style="{backgroundImage:'url('+domain+a.image+')'}">
                        <p class="case_imgs_box_p1">{{a.name}}</p>
                        <p class="case_imgs_box_p2">{{a.intro}}</p>
                    </div>
                </div>
            </div>
        </div>
        <ydFootBottom></ydFootBottom>
        <ydFootBottom2></ydFootBottom2>
    </div>
</template>

<script>
import ydHeaderTop from '@/components/ydHeaderTop.vue'
import ydFootBottom from '@/components/ydFootBottom.vue'
import ydFootBottom2 from '@/components/ydFootBottom2.vue'
import { getSkillDetailByType,getProductDetail,getIndexData,getAdvByType } from '@/assets/js/store.js'
export default {
    data () {
        return {
            pid:0,
            isName:'',
            typeif:1,
            domain:'',
            softwareinfo1:{},
            carouselUrl:'',
            homeData:[],
            // tab切换索引值
            tabIndex:0,
            isDuoka:0,
        };
    },

    components: {
        ydHeaderTop,
        ydFootBottom,
        ydFootBottom2
    },

    computed: {},

    mounted(){
        this.pid = this.$route.query.id
        this.isName = this.$route.query.names
        if(this.isName == '小程序定制开发'){
            this.typeif = 1
            this.getgetSkillDetailByType()
        }else if(this.isName == 'APP开发'){
            this.typeif = 2
            this.getgetSkillDetailByType()
        }else if(this.isName == 'H5/网站开发'){
            this.typeif = 3
            this.getgetSkillDetailByType()
        }else if(this.isName == '物联网/人工智能'){
            this.typeif = 4
            this.getgetSkillDetailByType()
        }else if(this.isName == '大数据/系统集成'){
            this.typeif = 5
            this.getgetSkillDetailByType()
        }else if(this.isName == '企业服务'){
            this.typeif = 6
            this.getgetSkillDetailByType()
        }
        this.getgetIndexData()
        this.getgetAdvByType()
    },

    methods: {
        // 获取首页数据
        async getgetIndexData(cid){
            const res = await getIndexData({cid})
            if(res.data.code == 1){
                this.homeData = res.data.data
                this.domain = res.data.data[2].headpath
                this.activeName = this.homeData[4].content.caseclass[0].name
                
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        // 获取技能详情页数据（小程序等）
        async getgetSkillDetailByType(){
            this.pid = this.$route.query.id
            const res = await getSkillDetailByType({type:this.typeif})
            if(res.data.code == 1){
                this.domain = res.data.data.headpath
                this.softwareinfo1 = res.data.data
                if(this.softwareinfo1.case.length==this.carouselIndex1+1){
                    this.carouselUrl = ''
                }else{
                    this.carouselUrl = this.softwareinfo1.case[this.carouselIndex1+1].showimage
                }
                
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        // 获取技能详情页数据（小程序等）
        async getgetProductDetail(){
            const res = await getProductDetail({pid:this.pid})
            if(res.data.code == 1){
                this.domain = res.data.data.headpath
                this.softwareinfo2 = res.data.data
                if(this.softwareinfo2.cases.length==this.carouselIndex1+1){
                    this.carouselUrl = ''
                }else{
                    this.carouselUrl = this.softwareinfo2.case[this.carouselIndex1+1].showimage
                }
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        async getgetAdvByType(){
            const res = await getAdvByType({type:1})
            if(res.data.code == 1){
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                showClose: true,
                message: res.data.msg,
                duration:3000,
                type: 'error'
                });
            }
        },
        // tab切换
        tabIndex1(item,index){
            this.tabIndex = index
            this.getgetIndexData(item.id)
        },
    }
}

</script>
<style lang='less' scoped>
@import '../assets/css/yd_software.css';
@import '../assets/css/yd_index.css';
</style>