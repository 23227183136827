<template>
  <div id="app">
    <div>
      <router-link to="/"></router-link> 
      
    </div>
    <router-view/>
  </div>
</template>
<script>
// import './assets/js/rem'

export default {
  name: '',
  data() {
			return {
        
			}
	},
  components: {
    
  },
  mounted(){
    
  },
  methods: {
    
  }
}
</script>

<style>
html,body,#app{
  overflow-x: hidden;
  /* height: 100%; */
  margin: 0;
  padding: 0;
}
p,span,ul,li{
  margin: 0;
  padding: 0;
}
</style>
