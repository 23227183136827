// 判断是否是手机端，如果是，返回true
function _isMobile(){
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}


if (_isMobile()) {
  // console.log('手机端')
  function ydMobile (win) {
    var doc = win.document;
    var docEl = doc.documentElement;
    var tid;
    function refreshRem() {
        var width = docEl.getBoundingClientRect().width;
        if (width > 375) { // 最大宽度
            width = 375;
        }
        var rem = width / 3.75;
        docEl.style.fontSize = rem + 'px';
    }
    win.addEventListener('resize', function () {
        clearTimeout(tid);
        tid = setTimeout(refreshRem, 300);
    }, false);
    win.addEventListener('pageshow', function (e) {
        if (e.persisted) {
            clearTimeout(tid);
            tid = setTimeout(refreshRem, 300);
        }
    }, false);
    refreshRem();
  }
  ydMobile(window)
} else {
  // console.log('pc端')
  // 基础设置为1px
  const baseSize = 1
  // 设置 rem 函数
  function setRem () {
    // 保留后四位  String(number).replace(/^(.*\..{4}).*$/,"$1")
    const scale = Number(String(document.documentElement.clientWidth / 1920).replace(/^(.*\..{4}).*$/,"$1"))
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
  }
  // 首次加载设置 rem
  setRem()
  // 改变窗口大小时重新设置 rem
  window.onresize = function () {
    setRem()
  }
}





