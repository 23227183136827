<!-- success 成功案例 -->
<template>
    <div class="success">
        <div class="Header">
            <HeaderTop :tabNum="tabNum"></HeaderTop>
            <div class="padd2-320">
                <p class="rsdk">认识多咖</p>
                <p class="succ-title asd" >精选案例，多种类跨行业</p>
                <p class="succ-title asd">实战经验</p>
                <img class="route1" style="margin-left:20rem;" src="../assets/img/lj1.png" alt="">
            </div>
        </div>
        <div class="succ-tab">
            <div :class="[tabIndex==index+1?'succ-tab-item succ-tab-active':'succ-tab-item']" @click="successInfo(item.id,index)" v-for="item,index in tabList" :key="index">{{item.name}}</div>
        </div>
        <div class="box-center">
            <div class="boxs-320">
                <div class="max-box" :style="{ 'background-image':'url('+domain+caseData.showimage+')'}">
                    <p class="case-title">{{caseData.name}}</p>
                    <div class="case-label">
                        <div v-for="a,b in caseData.label" :key="b">{{a}}</div>
                    </div>
                    <div class="case-cont" v-html="caseData.content"></div>
                    <img :src="domain+caseData.code" alt="">
                </div>
                <div class="tabList">
                    <img @click="lefts" class="tabList-img" v-if="asdd==false" @mouseenter="asdd=true" @mouseleave="" src="../assets/img/bann-left1.png" alt="">
                    <img @click="lefts" class="tabList-img" v-else @mouseenter="asdd=true" @mouseleave="asdd=false" src="../assets/img/bann-left2.png" alt="">
                    <div class="tabList-cont" :style="{}">
                        <div class="tabList-cont-item" :style="{'width':+successData.case.length*(210+16)+'rem','left':-lexindex*280+'rem'}">
                            <img :class="tabIndexs==index+1?'tabList-item tabList-items':'tabList-item '" @click="caseDatas(item,index)" v-for="item,index in successData.case" :key="index" :src="domain+item.image">
                        </div>
                    </div>
                    <img @click="rights" v-if="asdd2==false" @mouseenter="asdd2=true" @mouseleave="" class="tabList-img" src="../assets/img/bann-right1.png" alt="">
                    <img @click="rights" v-else @mouseenter="asdd2=true" @mouseleave="asdd2=false" class="tabList-img" src="../assets/img/bann-right2.png" alt="">
                    

                </div>
                <div style="height: 80rem;"></div>
            </div>
            <!-- <div class="aaa"></div> -->
            
        </div>
        <FootBottom2 :tabNum="tabNum"></FootBottom2>
    </div>
</template>

<script>
import HeaderTop from '@/components/HeaderTop.vue'
import FootBottom2 from '@/components/FootBottom2.vue'
import { getSuccessCaseData } from '@/assets/js/store.js'
export default {
    data () {
        return {
            tabNum:3,
            // 域名
            domain:'',
            tabList:[],
            //tab选项的索引值
            tabIndex:1,
            // 小列表的索引值
            tabIndexs:1,
            successData:[],
            // 案例展示数据
            caseData:{},
            lexindex:0,
            asdd:false,
            asdd2:false
        };
    },

    components: {
        HeaderTop,
        FootBottom2
    },

    computed: {},
    created(){
        
    },
    mounted(){
        
        if(this.$route.query.id){
            this.successInfo(this.$route.query.id,this.$route.query.id-1)
        }
        this.getgetSuccessCaseData()
    },

    methods: {
        lefts(){
            if(this.successData.case.length>4){
                if(this.lexindex!=0){
                    this.lexindex--
                }
            }
        },
        rights(){
            if(this.successData.case.length>4){
                this.lexindex++
                if(this.successData.case.length-this.lexindex==4){
                    this.lexindex--
                }
                console.log(this.lexindex);
            }
        },
        // 根据类型获取成功案例数据
        async getgetSuccessCaseData(){
            const res = await getSuccessCaseData()
            if(res.data.code == 1){
                this.tabList = res.data.data.caseclass
                this.successData = res.data.data
                this.caseData = res.data.data.case[0]
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        async successInfo(id,index){
            this.tabIndex = index+1
            this.tabIndexs = 1
            const res = await getSuccessCaseData({cid:id})
            if(res.data.code == 1){
                this.successData = res.data.data
                this.caseData = res.data.data.case[0]
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        caseDatas(item,index){
            this.tabIndexs = index+1
            this.caseData = item
        }
    }
}

</script>
<style lang='less' scoped>
@import '../assets/css/success.css';
</style>