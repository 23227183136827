<!-- case 案例详情 -->
<template>
    <div class="CASE">
        <!-- <div class="case-item" v-for="item,index in back" :key="index">
            <div class="case-item-minImg" :style="{ 'background-image':'url('+item.url1+')'}"></div>
            <div class="switch-tab2-1" :style="{ 'background-image':'url('+item.url2+')'}">
                <p class="switch-tab2-1-p1">鼓捣猫频道</p>
                <div class="switch-tab2-1-laber">
                    <div>电商平台</div>
                    <div>电商平台</div>
                    <div>电商平台</div>
                    <div>电商平台</div>
                </div>
                <p class="switch-tab2-1-p2">
                    需并放党象美受红线比物议自实边新学几叫回色东们离根斗报区史离打放入术题农置水动条。百件和论们发文能族前引把给通属领人真派省导解太两存知性记书那条质西活空海支所会长到整会界线实记科说回条果。
                </p>
                <img class="switch-tab2-1-ewm" :src="item.url3" alt="" v-show="item.url3 != ''">
            </div>
        </div> -->
    </div>
</template>

<script>
// import casemin1 from '../assets/img/case1.png'
// import casemin2 from '../assets/img/case2.png'
// import casemin3 from '../assets/img/case3.png'
// import casemin4 from '../assets/img/case4.png'
// import casemin5 from '../assets/img/case5.png'
// import casemin6 from '../assets/img/case6.png'
// import casemin7 from '../assets/img/case7.png'
// import casemin8 from '../assets/img/case8.png'
// import casemin9 from '../assets/img/case9.png'
// import casemin10 from '../assets/img/case10.png'
// import casemin11 from '../assets/img/case11.png'
// import casemin12 from '../assets/img/case12.png'
// import casemin13 from '../assets/img/case13.png'
// import casemin14 from '../assets/img/case14.png'
// import casemin15 from '../assets/img/case15.png'
// import casemin16 from '../assets/img/case16.png'
// import casemin17 from '../assets/img/case17.png'
// import casemin18 from '../assets/img/case18.png'
// import casemin19 from '../assets/img/case19.png'
// import casemin20 from '../assets/img/case20.png'
// import casemin21 from '../assets/img/case21.png'
// import casemin22 from '../assets/img/case22.png'
// import casemin23 from '../assets/img/case23.png'
// import casemin24 from '../assets/img/case24.png'
// import casemax1 from '../assets/img/case1-1.png'
// import casemax2 from '../assets/img/case2-1.png'
// import casemax3 from '../assets/img/case3-1.png'
// import casemax4 from '../assets/img/case4-1.png'
// import casemax5 from '../assets/img/case5-1.png'
// import casemax6 from '../assets/img/case6-1.png'
// import casemax7 from '../assets/img/case7-1.png'
// import casemax8 from '../assets/img/case8-1.png'
// import casemax9 from '../assets/img/case9-1.png'
// import casemax10 from '../assets/img/case10-1.png'
// import casemax11 from '../assets/img/case11-1.png'
// import casemax12 from '../assets/img/case12-1.png'
// import casemax13 from '../assets/img/case13-1.png'
// import casemax14 from '../assets/img/case14-1.png'
// import casemax15 from '../assets/img/case15-1.png'
// import casemax16 from '../assets/img/case16-1.png'
// import casemax17 from '../assets/img/case17-1.png'
// import casemax18 from '../assets/img/case18-1.png'
// import casemax19 from '../assets/img/case19-1.png'
// import casemax20 from '../assets/img/case20-1.png'
// import casemax21 from '../assets/img/case21-1.png'
// import casemax22 from '../assets/img/case22-1.png'
// import casemax23 from '../assets/img/case23-1.png'
// import casemax24 from '../assets/img/case24-1.png'
// import caseewm1 from '../assets/img/case1-2.png'
// import caseewm2 from '../assets/img/case2-2.png'
// import caseewm3 from '../assets/img/case3-2.png'
// import caseewm4 from '../assets/img/case4-2.png'
// import caseewm5 from '../assets/img/case5-2.png'
// import caseewm6 from '../assets/img/case6-2.png'
// import caseewm7 from '../assets/img/case7-2.png'
// import caseewm8 from '../assets/img/case8-2.png'
// import caseewm9 from '../assets/img/case9-2.png'
// import caseewm10 from '../assets/img/case10-2.png'
// import caseewm11 from '../assets/img/case11-2.png'
// import caseewm12 from '../assets/img/case12-2.png'
// import caseewm13 from '../assets/img/case13-2.png'
// import caseewm14 from '../assets/img/case14-2.png'
// import caseewm15 from '../assets/img/case15-2.png'
export default {
    data () {
        return {
            back:[
                // {url1:casemin1,url2:casemax1,url3:caseewm1},
                // {url1:casemin2,url2:casemax2,url3:caseewm2},
                // {url1:casemin3,url2:casemax3,url3:caseewm3},
                // {url1:casemin4,url2:casemax4,url3:caseewm4},
                // {url1:casemin5,url2:casemax5,url3:caseewm5},
                // {url1:casemin6,url2:casemax6,url3:caseewm6},
                // {url1:casemin7,url2:casemax7,url3:caseewm7},
                // {url1:casemin8,url2:casemax8,url3:caseewm8},
                // {url1:casemin9,url2:casemax9,url3:caseewm9},
                // {url1:casemin10,url2:casemax10,url3:caseewm10},
                // {url1:casemin11,url2:casemax11,url3:caseewm11},
                // {url1:casemin12,url2:casemax12,url3:caseewm12},
                // {url1:casemin13,url2:casemax13,url3:caseewm13},
                // {url1:casemin14,url2:casemax14,url3:caseewm14},
                // {url1:casemin15,url2:casemax15,url3:caseewm15},
                // {url1:casemin16,url2:casemax16,url3:''},
                // {url1:casemin17,url2:casemax17,url3:''},
                // {url1:casemin18,url2:casemax18,url3:''},
                // {url1:casemin19,url2:casemax19,url3:''},
                // {url1:casemin20,url2:casemax20,url3:''},
                // {url1:casemin21,url2:casemax21,url3:''},
                // {url1:casemin22,url2:casemax22,url3:''},
                // {url1:casemin23,url2:casemax23,url3:''},
                // {url1:casemin24,url2:casemax24,url3:''},
            ]
        };
    },

    components: {},

    computed: {},

    mounted(){},

    methods: {}
}

</script>
<style lang='less' scoped>
.CASE{
    padding: 60rem 112rem;
    box-sizing: border-box;
    .case-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30rem;
        .case-item-minImg{
                width: 305rem;
                height: 400rem;
                // background-image: url(../assets/img/case1.png);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: cover;
        }
    }
}
.switch-tab2-1{
    width: 1280rem;
    height: 622rem;
    border-radius: 24rem;
    // background-image: url(../assets/img/case1-1.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 54rem 0 0 48rem;
    box-sizing: border-box;
}
.switch-tab2-1-p1{
    font-weight: 600;
    font-size: 55rem;
    color: #333333;
}
.switch-tab2-1-laber{
    display: flex;
    align-items: center;
}
.switch-tab2-1-laber div{
    width: 90rem;
    height: 30rem;
    background: #E6ECF4;
    border-radius: 15.48rem;
    text-align: center;
    line-height: 30rem;
    font-weight: 400;
    font-size: 12rem;
    color: #60636A;
    margin-right: 10rem;
    margin-top: 20rem;
}
.switch-tab2-1-p2{
    width: 388rem;
    font-weight: 400;
    font-size: 18rem;
    color: #808080;
    letter-spacing: 0;
    line-height: 31rem;
    margin-top: 43rem;
}
.switch-tab2-1-ewm{
    width: 124rem;
    height: 124rem;
    margin-top: 30rem;
}
</style>