<!-- FootBottom2 -->
<template>
    <div class="FootBottom2">
        <div class="FootBottom2-box" v-if="publicContents">
            <div style="display: flex;">
                <ul class="ul1" v-for="item,index in publicContents.bottomcontent">
                    <li style="font-weight: 600;font-size: 20rem;opacity: 1;color: #FFFFFF;"  @click="fatherUrl(item,index)">{{item.name}}</li>
                    <li class="lis" v-for="items,indexs in item.son" @click.stop="sonUrl(items,indexs)">{{items.name}}</li>
                </ul>
            </div>
            <div class="contactDK" v-if="publicContents">
                <div class="contacts">
                    联系方式
                </div>
                <div class="contactDKItem">
                    E-mail：wjz@duoka361.com
                </div>
                <div class="contactDKItem">
                    电话：{{publicContents.contact}}
                </div>
                <div class="contactDKItem">
                    地址：北京朝阳区通惠河南路1081号环宇国际大厦12层
                </div>
                <div style="overflow: hidden;">
                    <div class="ewm">
                        <img :src="domain+publicContents.gzh" alt="">
                        <p>{{publicContents.gzhwords}}</p>
                    </div>
                    <div class="ewm" style="margin-left: 48rem; ">
                        <img :src="domain+publicContents.wx" alt="">
                        <p>{{publicContents.wxwords}}</p>
                    </div>
                </div>
                <!-- <p class="phone">{{publicContents.contact}}</p> -->
                <!-- <p class="contactDK-p1">24小时服务热线</p> -->
                <!-- <div class="FootBottom-btn" @click="btn1">
                    <p>马上咨询</p>
                    <img src="../assets/img/bottomYjt.png" alt="">
                </div> -->
            </div>
        </div>
        <div class="FootBottom2-box2" v-if="publicContents">
            <p>{{publicContents.copy}}{{publicContents.beian}}</p>
            <p>{{publicContents.gwab}}</p>
        </div>
    </div>
</template>

<script>
import { getCommonData } from '@/assets/js/store.js'
export default {
    props: {
        tabNum: Number
    },
    data () {
        return {
            // 域名
            domain:'',
            // 公共内容
            publicContents:{}
        };
    },

    components: {},

    computed: {},

    mounted(){
        this.getgetCommonData()
    },

    methods: {
        btn1(){
            //document.getElementById("mini-btn").click();
            document.getElementsByClassName("embed-icon-default")[0].click()
        },
        async getgetCommonData(){
            const res = await getCommonData()
            if(res.data.code == 1){
                this.publicContents = res.data.data
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        fatherUrl(item,index){
            console.log(item);
            if(item.url == ''){
                if(this.tabNum!=0){
                    this.$router.push('/')
                }else{
                    const element = document.getElementById("wedo1");
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }else if(item.url == '/success'){
                if(this.tabNum!=0){
                    this.$router.push('/')
                }else{
                    const element = document.getElementById("wedo2");
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }else if(item.url == '/information'){
                if(this.tabNum!=0){
                this.$router.push(item.url)
                }else{
                const element = document.getElementById("wedo3");
                element.scrollIntoView({ behavior: "smooth" });
                }
            }else if(item.url == '/wedo4'){
                if(this.tabNum!=0){
                    this.$router.push('/')
                }else{
                    const element = document.getElementById("wedo4");
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }else if(item.url == '/about'){
                this.$router.push(item.url)
            }else if(item.url == '/software'){
                this.$router.push({path:'/software',query: {id: 1,type: 1,names:'小程序定制开发'}})
            }
        },
        sonUrl(item,index){
            console.log(item);
            if(item.url == '/software'){
                this.$router.push({path:'/software',query: {id: 1,type: 1,names:'小程序定制开发'}})
            }else if(item.url == '/information' || item.url == '/about'){
                this.$router.push(item.url)
            }else if(item.url == '/success'){
                if(this.tabNum!=0){
                    this.$router.push('/')
                }else{
                    const element = document.getElementById("wedo2");
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }else{
                this.btn1()
            }
        }
    }
}

</script>
<style lang='less' scoped>
.FootBottom2{
    width: 100%;
    height: 563rem;
    background: #000000;
    .FootBottom2-box{
        width: 100%;
        height: 500rem;
        padding-left: 170rem;
        padding-right: 170rem;
        padding-top: 70rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .ul1{
            list-style: none;
            font-weight: 400;
            font-size: 16rem;
            color: #FFFFFF;
            margin-right: 130rem;
        }
        .ul1 li{
            margin-bottom: 30rem;
            opacity: 0.68;
            cursor:pointer;
        }
        .ul1 li:hover{
            color: #ffffff;
            opacity: 1;
        }
        .contactDK{
            // margin-left: 184rem;
            .contacts{
                font-weight: 600;
                font-size: 20rem;
                color: #FFFFFF;
                margin-bottom: 30rem;
            }
            .contactDKItem{
                color: #ffffff;
                font-size: 18rem;
                font-weight: 400;
                margin-bottom: 20rem;
                opacity: 0.68;
            }
            .ewm{
                float: left;
                margin-top: 8rem;
                img{
                    width: 130rem;
                    height: 130rem;
                }
                p{
                    font-weight: 400;
                    font-size: 16rem;
                    color: #FFFFFF;
                    margin-top: 12rem;
                }
            }
            .phone{
                font-weight: 600;
                font-size: 37rem;
                color: #FFFFFF;
                margin-top: 76rem;
            }
            .contactDK-p1{
                font-weight: 400;
                font-size: 18rem;
                color: #FFFFFF;
                opacity: 0.68;
            }
            .FootBottom-btn{
                width: 158rem;
                height: 52rem;
                background-image: linear-gradient(180deg, #6A80F9 0%, #2743F5 100%);
                border-radius: 4rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20rem;
                box-sizing: border-box;
                margin-top: 22rem;
                cursor:pointer;
                p{
                    font-weight: 600;
                    font-size: 18rem;
                    color: #FFFFFF;
                }
                img{
                    width: 22rem;
                    height: 12rem;
                }
            }
        }
    }
    .FootBottom2-box2{
        width: 100%;
        height: 63rem;
        border-top:1rem solid #4F4F4F;
        padding: 0 320rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: 12rem;
        color: #FFFFFF;
        opacity: 0.68;
    }
}

</style>