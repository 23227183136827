<!--  -->
<template>
    <div class="ydFootBottom2">
        <ul class="ydFootBottomUL" v-show="false">
            <li @click="routerUrl(b+1)" v-for="a,b in tabList" :key="b">
                <p>{{a.name}}</p>
                <img src="../assets/yd_img/liy.png" alt="">
            </li>
            <!-- <li @click="routerUrl(2)">
                <p>软件产品</p>
                <img src="../assets/yd_img/liy.png" alt="">
            </li>
            <li @click="routerUrl(3)">
                <p>关联服务解决方案</p>
                <img src="../assets/yd_img/liy.png" alt="">
            </li>
            <li @click="routerUrl(4)">
                <p>成功案例</p>
                <img src="../assets/yd_img/liy.png" alt="">
            </li>
            <li @click="routerUrl(5)">
                <p>资讯动态</p>
                <img src="../assets/yd_img/liy.png" alt="">
            </li>
            <li @click="routerUrl(6)">
                <p>关于我们</p>
                <img src="../assets/yd_img/liy.png" alt="">
            </li> -->
        </ul>
        <div class="ydFootBottomCenter">
            <div class="company">
                <p class="Contact">联系方式</p>
                <div class="company_info">
                    <p style="opacity: 0.55;">电话：{{phone}}</p>
                </div>
                <div class="company_info">
                    <p style="opacity: 0.55;">E-mail：wjz@duoka361.com</p>
                </div>
                <div class="company_info">
                    <p style="opacity: 0.55;">地址：北京朝阳区通惠河南路1081号环宇国际大厦12层</p>
                </div>
                <div class="erm" v-if="publicContent">
                    <div class="erm1">
                        <img :src="domain+publicContent.gzh" alt="">
                        <p style="opacity: 0.68;margin-top: 0.14rem;">{{publicContent.gzhwords}}</p>
                    </div>
                    <div class="erm1">
                        <img :src="domain+publicContent.wx" alt="">
                        <p style="opacity: 0.68;margin-top: 0.14rem;">{{publicContent.wxwords}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="company_list">
            <ul class="ul1" v-for="item,index in publicContent.bottomcontent">
                <li style="font-weight: 600;font-size: 0.16rem;opacity: 1;color: #FFFFFF;"  @click="fatherUrl(item,index)">{{item.name}}</li>
                <li class="lis" v-for="items,indexs in item.son" @click.stop="sonUrl(items,indexs)">{{items.name}}</li>
            </ul>
        </div>
        <div class="ydFootBottomBottom" v-if="publicContent">
            <p>{{publicContent.copy}}{{publicContent.beian}}</p>
            <p>{{publicContent.gwab}}</p>
        </div>
    </div> 
</template>

<script>
import { getCommonData } from '@/assets/js/store.js'
import { nextTick } from 'vue';
export default {
    data () {
        return {
            // 域名
            domain:'',
            // tab列表
			tabList:[],
            // logo图片
            logoImg:'',
            phone:'',
            publicContent:{}
        };
    },
    props: {
        tabNum: Number
    },
    components: {},

    computed: {},

    mounted(){
        this.getgetCommonData()
    },

    methods: {
        // 获取公共部分信息
        async getgetCommonData(){
            const res = await getCommonData()
            if(res.data.code == 1){
            this.tabList = res.data.data.topcolumn
            this.logoImg = res.data.data.logo
            this.domain = res.data.data.headpath
            this.phone = res.data.data.contact
            this.publicContent = res.data.data
            // this.tabList[0].son.forEach((item,index) => {
            //     this.sonList.push(item)
            // })
            }else{
            this.$message({
                showClose: true,
                message: res.data.msg,
                duration:3000,
                type: 'error'
            });
            }
            
        },
        routerUrl(x){
            // if(a.url == ''){
            //     return
            // }
            // this.$router.push(a.url)
            // return
            switch (x) {
                case 1:
                    break;
                case 2:
                    // this.$router.push('/ydSoftware')
                    break;
                case 3:
                    this.$router.push('/ydSuccess')
                    break;
                case 4:
                    this.$router.push('/ydInformation')
                    break;
                case 5:
                    this.$router.push('/ydAbout')
                    break;
                case 6:
                    break;
                default:
                    console.log('不满足上述所有case时, 执行默认')
            }
        },
        fatherUrl(item,index){
            nextTick(() => {
            console.log(item);
            if(item.url == ''){
                if(this.tabNum!=0){
                    this.$router.push('/')
                }else{
                    const element = document.getElementById("wedo1");
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }else if(item.url == '/success'){
                if(this.tabNum!=0){
                    this.$router.push('/')
                }else{
                    const element = document.getElementById("wedo2");
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }else if(item.url == '/information'){
                if(this.tabNum!=0){
                this.$router.push({path:'/ydInformation',query: {type: 1}})
                }else{
                const element = document.getElementById("wedo3");
                element.scrollIntoView({ behavior: "smooth" });
                }
            }else if(item.url == '/wedo4'){
                if(this.tabNum!=0){
                    this.$router.push('/')
                }else{
                    const element = document.getElementById("wedo4");
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }else if(item.url == '/about'){
                this.$router.push('/ydAbout')
            }else if(item.url == '/software'){
                this.$router.push({path:'/ydSoftware',query: {id: a.id,type: 1,names:a.name}})
            }
            })
        },
        sonUrl(item,index){
            // console.log(item);
            if(item.url == '/software'){
                this.$router.push({path:'/ydSoftware',query: {id: a.id,type: 1,names:a.name}})
            }else if(item.url == '/about'){
                this.$router.push('/ydAbout')
            }else if(item.url == '/information'){
                this.$router.push({path:'/ydInformation',query: {type: 1}})
            }else if(item.url == '/success'){
                if(this.tabNum!=0){
                    this.$router.push('/')
                }else{
                    const element = document.getElementById("wedo2");
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }else{
                this.btn1()
            }
        }
    }
}

</script>
<style lang='less' scoped>
.ydFootBottom2{
    width: 100%;
    background: #000000;
}
.ydFootBottomUL{
    padding: 0 0.16rem;
    box-sizing: border-box;
}
.ydFootBottomUL li{
    width: 100%;
    height: 0.53rem;
    border-bottom: 0.01rem solid #4F4F4F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 0.15rem;
    color: #FFFFFF;
}
.ydFootBottomUL img{
    width: 0.08rem;
    height: 0.13rem;
}
.ydFootBottomCenter{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.15rem 0.45rem 0.15rem;
    box-sizing: border-box;
}
.company_list{
    display: flex;
    flex-wrap: wrap;
    padding-left:0.45rem;
    box-sizing: border-box;
}
.ul1{
    list-style: none;
    font-weight: 400;
    font-size: 0.13rem;
    color: #FFFFFF;
    margin-bottom: 0.6rem;
}
.ul1:nth-child(3){
    margin-bottom: 0.34rem;
}
.ul1:nth-child(4){
    margin-bottom: 0.34rem;
}
.ul1 li{
    margin-bottom: 0.15rem;
    opacity: 0.68;
    cursor:pointer;
}
.lis{
    width: 1.58rem;
}
.ul1 li:hover{
    color: #ffffff;
    opacity: 1;
}
.company{

}
.Contact{
    font-weight: 600;
    font-size: 0.16rem;
    color: #FFFFFF;    
    margin-bottom: 0.09rem;
}
.company_info{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.13rem;
    color: #FFFFFF;
    margin-bottom: 0.17rem;
}
.company_info img{
    width: 0.16rem;
    height: 0.16rem;
}
.erm{
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
}
.erm1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    font-size: 0.13rem;
    color: #FFFFFF;
    margin-right: 0.33rem;
    text-align: center;
}
.erm1 img{
    width: 1.47rem;
    height: 1.47rem;
}

.ydFootBottomBottom{
    width: 100%;
    height: 0.56rem;
    border-top: 0.01rem solid #4F4F4F;
    opacity: 0.68;
    font-weight: 400;
    font-size: 0.1rem;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
</style>