<!--  -->
<template>
    <div class="FootBottom" @click="btn1" :style="{ 'background-image':'url('+domain+logoImg+')'}">
        <!-- <p class="p1">一站式软件开发，你身边的软件！</p>
        <p class="p1">工程专家</p>
        <div class="FootBottom-btn">
            <p>马上咨询</p>
            <img src="http://xzq.duoka361.cn/duoka/yjt2.png" alt="">
        </div> -->
    </div>
</template>

<script>
import { getCommonData } from '@/assets/js/store.js'

export default {
    data () {
        return {
            // 域名
            domain:'',
            // logo图片
            logoImg:''
        };
    },

    components: {},

    computed: {},

    mounted(){
        this.getgetCommonData()
    },

    methods: {
        btn1(){
            //document.getElementById("mini-btn").click();
      document.getElementsByClassName("embed-icon-default")[0].click()
        },
        // 获取公共部分信息
        async getgetCommonData(){
            const res = await getCommonData()
            if(res.data.code == 1){
                this.logoImg = res.data.data.slogn
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
            
        },
    }
}

</script>
<style lang='less' scoped>
.FootBottom{
    width: 100%;
    height: 268rem;
    // background-image: url(http://xzq.duoka361.cn/duoka/FootBottom.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 867rem;
    padding-top: 34rem;
    box-sizing: border-box;
    .p1{
        font-weight: 600;
        font-size: 44rem;
        color: #FFFFFF;
    }
    .FootBottom-btn{
        cursor:pointer;
        width: 158rem;
        height: 52rem;
        background: #FFFFFF;
        border-radius: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20rem;
        box-sizing: border-box;
        margin-top: 22rem;
        p{
            font-weight: 600;
            font-size: 18rem;
            color: #2B47F6;
        }
        img{
            width: 22rem;
            height: 12rem;
        }
    }
}
</style>