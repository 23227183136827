<!-- 移动端首页 -->
<template>
    <div>
        <!-- <ydHeaderTop :tabNum='0'></ydHeaderTop> -->
        <div class="ydHeader" :style="{ 'background-image':'url('+require('../assets/yd_img/headerBack.png')+')'}">
            <!-- <div class="rsdks1">{{headerData.sign}}</div> -->
            
            <!-- <div class="scroll-wrap">
              <el-carousel  direction="vertical" :autoplay="true" indicator-position="none" :interval="2000">
                <el-carousel-item>
                  <p style="color:#2B47F6;">小程序</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">app</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">H5/网站</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">物联网/人工智能</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">大数据/系统集成</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">企业服务</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化电商</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化场馆</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化汽服</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化旅游</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化养老</p>
                </el-carousel-item>
                <el-carousel-item>
                  <p style="color:#2B47F6;">数智化医疗</p>
                </el-carousel-item>
              </el-carousel>
            </div> -->
            <p class="ydHeaderp2" style="margin-top: 0.25rem;">
                全平台软件开发
            </p>
            <p class="ydCenterItemP">十年软件开发经验 专人维护 售后有保障</p>

            <div class="ydCenter">
                <div class="ydCenterItem">
                    <p>全网小程序定制开发</p>
                    <p>APP定制开发</p>
                    
                </div>
                <div class="ydCenterItem">
                    <p> H5/网站定制开发</p>
                    <p>多行业定制服务开发</p>
                </div>
            </div>

            <!-- <p class="ydHeaderp1" >全网小程序定制开发</p>
            <p class="ydHeaderp1" >APP定制开发</p>
            <p class="ydHeaderp1" >H5/网站定制开发</p>
            <p class="ydHeaderp1" >多行业定制服务开发</p>
            <p class="ydHeaderp1" style="margin-top: 0.25rem;">十年软件开发经验 专人维护 售后有保障</p> -->
            
            <!-- <div class="herder_btn1" v-if="headerData.btn">
                <div class="tjwx1" @click="btn1(0)">{{headerData.btn[0]}}</div>
                <div class="mszx1" @click="btn1(1)">
                    {{headerData.btn[1]}}
                    <img class="mszx_img" src="../assets/yd_img/yjt1.png" alt="">
                </div>
            </div>
            <div class="develop">
                <div class="developItem">
                    <div class="developTop">
                        <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/06/wKrMZ24eXQ3V050k1md06/x83ym9xezc1pdgvm/1c42cf05-7bad-4610-9e59-0e9b6510be74.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                        <p>10年</p>
                    </div>
                    <p class="developTitle">深耕软件开发</p>
                    <div></div>
                </div>
                <div class="developItem">
                    <div class="developTop">
                        <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/06/wKrMZ24eXQ3V050k1md06/x83ym9xezc1pdgvm/aef69f0f-185e-4d75-b178-5a7cf04fd980.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                        <p>100%</p>
                    </div>
                    <p class="developTitle">交付保障</p>
                    <div></div>
                </div>
                <div class="developItem">
                    <div class="developTop">
                        <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/08/wKrMZ24eXQ3V050k1md08/yootj0wusuttmrmd/5631b5af-9a03-4a5b-84f9-e224add6d487.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                        <p>7*24h</p>
                    </div>
                    <p class="developTitle">及时响应</p>
                    <div></div>
                </div>
            </div> -->
        </div>
        <!-- 我们能为您做什么？ -->
        <div class="padd-15" style="background:#FFFFFF">
            <div class="indexTitle"  id="wedo1">服务项目</div>
            <div class="Dowhat" v-if="homeData[0]">
                <div class="Dowhat_item" v-for="item,index in homeData[0].content" @click="softwareUrl(item,1)">
                    <div class="Dowhat_top">
                        <img class="Dowhat_img" :src="domain+item.image" alt="">
                        <p class="Dowhat_title">{{item.name}}</p>
                        <p class="Dowhat_title2">{{item.intro}}</p>
                    </div>
                    <p class="Dowhat_p">{{item.words1}}</p>
                    <p class="Dowhat_p" style="margin-top: 0rem;">{{item.words2}}</p>
                </div>
            </div>
        <div style="height:0.2rem"></div>
        </div>
        
        <div style="background: #FFFFFF;">
            <div style="height:0.01rem"></div>
            <div class="case_title" id="wedo2">
                <div class="indexTitle" style="font-size: 600;">客户服务类型</div>
            </div>
            <div style="height:0.2rem"></div>
            <div class="nice">
                <div class="niceItem">电商类</div>
                <div class="niceItem">O2O/2C类</div>
                <div class="niceItem">社区/社群/社交</div>
                <div class="niceItem">短视频直播类</div>
                <div class="niceItem">教育类</div>
                <div class="niceItem">医疗系统类</div>
                <div class="niceItem">工具类</div>
                <div class="niceItem">咨询门户类</div>
                <div class="niceItem">更多类别</div>
            </div>
            <div style="height:0.2rem"></div>
        </div>
        <div class="case_title" id="wedo2">
                <div class="indexTitle" style="font-size: 600;">客户案例</div>
            </div>
            <div class="case_tabs" v-if="homeData[4]">
                <div class="case_tab2" :style="{'width':homeData[4].content.caseclass.length*(1.1)+'rem'}">
                    <div :class="tabIndex==index?'case_tab_item1 case_tab_item3':'case_tab_item1'" @click="tabIndex1(item,index)" v-for="item,index in homeData[4].content.caseclass" :key="index">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="case_imgs" v-if="homeData[4]">
                <div class="case_imgs_box" :style="{'width':9.5+'rem'}">
                    <div class="img" v-for="a,b in homeData[4].content.case" :key="b" :style="{backgroundImage:'url('+domain+a.image+')'}">
                        <p class="case_imgs_box_p1">{{a.name}}</p>
                        <p class="case_imgs_box_p2">{{a.intro}}</p>
                    </div>
                </div>
            </div>

        <!-- 解决方案 -->
        <!-- <div v-if="homeData[1]" id="wedo4">
            <div style="height: 0.3rem;background: #FFFFFF;"></div>
            <div class="solution" v-if="homeData[1]">
                <div class="indexTitle" style="margin-top:0;margin-bottom: 0.19rem;">客户案例</div>
                <div class="solutionTab">
                    <div :class="[isDuoka==index?'solutionItem solutionItem2':'solutionItem']"  v-for="item,index in homeData[1].content" :key="index" @click="solve(index)">
                        <img :src="domain+item.selectimage" alt="" v-if="isDuoka==index">
                        <img :src="domain+item.image" v-else>
                        <p v-if="isDuoka==index" style="color:#2B47F6;">{{item.name}}</p>
                        <p v-else>{{item.name}}</p>
                        <div class="solutionItem3"></div>
                    </div>
                    <div style="width:0.4rem;"></div>
                </div>
            </div>
            <div class="solutionBox">
                <div class="solutionBoxTitle">{{homeData[1].content[isDuoka].name}}</div>
                <div class="solutionBoxInfo" v-if="homeData[1].content[isDuoka].intro.length==1">{{homeData[1].content[isDuoka].intro[0]}}</div>
                <div class="solutionBoxInfo" v-else v-for="a,b in homeData[1].content[isDuoka].intro" :key="b">{{a}}<br></div>
                <div class="solutionBoximgs">
                    <img v-for="x,y in homeData[1].content[isDuoka].images" :key="y" :src="domain+x" alt="">
                </div>
                <div class="solutionMaxboxs">
                    <div class="solutionMinbox" v-for="x,y in homeData[1].content[isDuoka].did" :key="y">
                        <div class="solutionMinboxTitle">{{x.name}}</div>
                        <div class="solutionMinboxInfo">{{x.intro}}</div>
                        <img :src="domain+x.image">
                    </div>
                </div>
                <div class="demo" >
                    <p>预约演示</p>
                    <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/08/wKrMZ24eXQ3V050k1md08/v0h4gijblrtkzvyi/b12e831e-73a6-42d9-9828-6093937d6c4a.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                </div>
            </div>
        </div> -->
        <!-- 行业软件产品服务 -->
        <!-- <div class="Industry">
            <div class="indexTitle" style="margin-top:0rem;margin-bottom: 0rem;">我们能为您做什么？</div>
            <img src="../assets/yd_img/yu.png" alt="">
        </div> -->
        <!-- 服务优势 -->
        <div class="padd-15 Service">
            <div class="indexTitle">服务优势</div>
            <ul class="dkkj_ul" v-if="homeData[2]">
                <li class="dkkj_li" v-for="item,index in homeData[2].content" :key="index">
                    <img class="dkkj_img" :src="domain+item.image" alt="">
                    <div class="dkkj_top">
                        <p class="dkkj_p1">{{item.name}}</p>
                        <p class="dkkj_p2">{{item.intro}}</p>
                    </div>
                </li>
            </ul>
            <div style="height:0.5rem;"></div>
        </div>
        <div class="back_box">
            <!-- 开发流程 -->
            <!-- <div class="process">
                <div class="indexTitle" style="color: #FFFFFF;">开发流程</div>
                <div class="processBox">
                    <div class="process_img">
                        <div class="process_lan"></div>
                        <div class="process_img_title" v-if="homeData[3]">
                            <div class="_img_title" v-for="item,index in homeData[3].content" :key="index">
                                <img :src="domain+item.image" alt="">
                                <div>
                                    <p class="process_p1">{{item.name}}</p>
                                    <p class="process_p2">{{item.intro}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height:0.2rem;"></div>
            </div> -->
            <!-- 客户案例  -->
            
            <!-- <div class="case_imgs3" v-if="homeData[6]" style="margin-top: 0.28rem;">
                <div class="case_imgsbox3">
                    <img :src="domain+item" v-for="item,index in hzhbImg1" alt="">
                </div>
            </div>
            <div class="case_imgs3" v-if="homeData[6]" style="margin-top: 0.05rem;">
                <div class="case_imgsbox4">
                    <img :src="domain+item" v-for="item,index in hzhbImg2" alt="">
                </div>
            </div> -->
            <!-- 公司新闻资讯  -->
            <!-- <div class="indexTitle" id="wedo3" style="font-size: 600;margin-top: 0.48rem;">新闻资讯</div>
            <div v-if="homeData[7]" class="News">
                <div class="min_new3" @click="realtime(2,item)"  v-for="item,index in homeData[7].content" :key="index">
                    <div class="min_new_info">
                        <p class="min_new_p3">{{item.name}}</p>
                        <p class="min_new_p2">{{item.showtime}}</p>
                    </div>
                    <img :src="domain+item.image" alt="">
                </div>
            </div>
            <div class="mszx2" @click="realtime(1)">
                更多资讯
                <img class="mszx_img2" src="../assets/yd_img/yjt1.png" alt="">
            </div> -->
            <div style="height:0.27rem;"></div>
        </div>
        <!-- <ydFootBottom></ydFootBottom> -->
        <ydFootBottom2  :tabNum='0'></ydFootBottom2>
    </div>
</template>

<script>
import ydHeaderTop from '@/components/ydHeaderTop.vue'
import ydFootBottom from '@/components/ydFootBottom.vue'
import ydFootBottom2 from '@/components/ydFootBottom2.vue'
import { getAdvByType,getIndexData } from '@/assets/js/store.js'
export default {
    data () {
        return {
            // 域名
            domain:'',
            // 头部数据
            headerData:{},
            // 首页数据
            homeData:{},
            // tab切换索引值
            tabIndex:0,
            isDuoka:0,
            isDuoka2:0,
            // 合作伙伴的图片
            hzhbImg1:[],
            hzhbImg2:[],
        };
    },

    components: {
        ydHeaderTop,
        ydFootBottom,
        ydFootBottom2
    },

    computed: {},

    mounted(){
        console.log(this.$route.path);
        this.getgetAdvByType()
        this.getgetIndexData()
        // 根据不同路由跳转不同页面
        if (this._isMobile()) {
            if(this.$route.path != '/wap/ydIndex'){
                this.$router.replace('/wap/ydIndex')
            }
        } else {
            if(this.$route.path != '/'){
                this.$router.replace('/')
            }
        }
    },

    methods: {
        // 判断是否是手机端，如果是，返回true
        _isMobile () {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag
        },
        // 根据类型获取广告（顶部Banner）
        async getgetAdvByType(){
            const res = await getAdvByType({type:1})
            if(res.data.code == 1){
                this.domain = res.data.data.headpath
                this.headerData = res.data.data
            }else{
                this.$message({
                showClose: true,
                message: res.data.msg,
                duration:3000,
                type: 'error'
                });
            }
        },
        // 获取首页数据
        async getgetIndexData(cid){
            const res = await getIndexData({cid})
            if(res.data.code == 1){
                this.homeData = res.data.data
                this.activeName = this.homeData[4].content.caseclass[0].name
                this.hzhbImg1=[]
                this.hzhbImg2=[]
                let num = res.data.data[6].content.length
                if(num%2==0){
                    num = num/2
                }else{
                    num = num/2
                    num = Math.ceil(num)
                }
                res.data.data[6].content.forEach((item,index) => {
                    if(index<=num){
                        this.hzhbImg1.push(item.image)
                    }else{
                        this.hzhbImg2.push(item.image)
                    }
                });
            }else{
                this.$message({
                showClose: true,
                message: res.data.msg,
                duration:3000,
                type: 'error'
                });
            }
        },
        btn1(x){
            window.location.href.href = this.headerData.btnurl[x]
        },
        // tab切换
        tabIndex1(item,index){
            this.tabIndex = index
            this.getgetIndexData(item.id)
        },
        succsee(){
            this.$router.push({path:'/ydSuccess',query: {id:this.tabIndex+1}})
        },
        solve(index){
            this.isDuoka = index
            this.isDuoka2 = 0
        },
        // 软件产品跳转（小程序开发等）
        softwareUrl(item,x){
            // console.log(item,x);
            this.$router.push({path:'/ydSoftware',query: {id: item.id,type: x,names:item.name}})
        },
        realtime(x,item){
            if(x==1){
                this.$router.push({path:'/ydInformation',query: {type: x}})
            }else{
                this.$router.push({path:'/ydInformation',query: {id: item.id,type: x}})
            }
        }
    }
}

</script>
<style lang='less' scoped>
@import '../assets/css/yd_index.css';
</style>