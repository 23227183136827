<!-- software 软件产品  -->
<template>
    <div>
        <!-- 头部 -->
        <div class="header-top8">
            <HeaderTop :tabNum="tabNum"></HeaderTop>
            <p class="header-top8-p1">商业智能技术开发服务商</p>
            <p class="header-top8-p2">您身边的软件技术专家！</p>
        </div>
        <!-- 小程序开发 -->
        <p class="title-p1">小程序开发，抓住亿万级移动流量红利！</p>
        <p class="title-p2">提供完整的解决方案，助力精准高效获取用户</p>
        <div class="fun-list">
            <div class="fun-item">
                <img src="../assets/img/kf.png" alt="">
                <p class="fun-p1">开发文档</p>
                <p class="fun-p2">介绍小程序的开发框架、基础组件及API等</p>
            </div>
            <div class="fun-item">
                <img src="../assets/img/kfz.png" alt="">
                <p class="fun-p1">开发者工具</p>
                <p class="fun-p2">丰富的框架、组件、API、开发工具支持小程序开发</p>
            </div>
            <div class="fun-item">
                <img src="../assets/img/sj.png" alt="">
                <p class="fun-p1">设计指南</p>
                <p class="fun-p2">标准的设计原则、规范、组件化资源支持小 程序设计</p>
            </div>
            <div class="fun-item">
                <img src="../assets/img/yy.png" alt="">
                <p class="fun-p1">运营接入</p>
                <p class="fun-p2">标准化规范运营、多渠道多方式接入</p>
            </div>
        </div>
        <!-- 开发范围 -->
        <p class="title-p1" style="margin-top: 100rem;">开发范围</p>
        <p class="title-p2">采用前沿全平台适配框架，开发一套，兼容多平台小程序</p>
        <div class="scope">
            <div class="scope-item">
                <img class="maxImg" src="../assets/img/wx.png" alt="">
                <div class="scope-icon">
                    <img src="../assets/img/wx1.png" alt="">
                    <p>微信小程序</p>
                </div>
            </div>
            <div class="scope-item">
                <img class="maxImg" src="../assets/img/zfb.png" alt="">
                <div class="scope-icon">
                    <img src="../assets/img/zfb1.png" alt="">
                    <p>支付宝小程序</p>
                </div>
            </div>
            <div class="scope-item">
                <img class="maxImg" src="../assets/img/bd.png" alt="">
                <div class="scope-icon">
                    <img src="../assets/img/bd1.png" alt="">
                    <p>百度小程序</p>
                </div>
            </div>
            <div class="scope-item">
                <img class="maxImg" src="../assets/img/dy.png" alt="">
                <div class="scope-icon">
                    <img src="../assets/img/dy1.png" alt="">
                    <p>抖音小程序</p>
                </div>
            </div>
        </div>
        <div class="Consult" @click="btn1">
            <p>立即咨询</p>
            <img class="ConsultImg1" src="../assets/img/yjt2.png" alt="">
            <img class="ConsultImg2" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAYCAYAAACBbx+6AAAAAXNSR0IArs4c6QAAAV1JREFUWEfdl7FOwzAYhO/oBAjxgIiNZ2Bg5AEq9WEqMfESfQK2CjohsVSQQxfZUmqsJg0KjvGSJXY+n+/+/CYKDUn0p0nqFIR2Uqkh6QyAToEuAizpGsADgEeSH5IWJL+GCPenwLaB1ZR0DuAdwDOAG5K7odClgC8AbAFcAdgE6I2hATTHLFIS+BXAZbCB4e9IrvugSwK/AbDS9q6V3QO4J7kKYXQFaVJf/wCO5WZIAEa8Yw83kqysFTawRwRz1VgF8H3O13NQ2HXYHH4a3Gqvg0W2KXRO4bjrEQL2TvH3PkPYXoLCEdiTu9AHYYxlrwXulBvDeiGn1wtPeQLHhIm+3gG4JflkX9tOOeAYhu7Oe6Wb4IUI7afDuGyFzShcHfD8LdE9TknzD90E/ssumYQ7td7osjZlZfgXP47f/ZqntEdiieqan6rayzoa+Gi3aq5IaT6quYTGdmDMNf8bUVQ0JNA7IbwAAAAASUVORK5CYII=" alt="">
        </div>
        <!-- 解决方案 -->
        <p class="title-p1" style="margin-top: 20rem;">解决方案</p>
        <p class="title-p2">持续探索，具有前沿领域技术服务经验,并形成了稳健解决方案</p>
        <div class="scope">
            <div class="scope-item2">
                <img class="maxImg2" src="../assets/img/ds.png" alt="">
                <div class="scope-icon2">
                    <p>电商零售</p>
                </div>
            </div>
            <div class="scope-item2">
                <img class="maxImg2" src="../assets/img/jk.png" alt="">
                <div class="scope-icon2">
                    <p>大健康医疗</p>
                </div>
            </div>
            <div class="scope-item2">
                <img class="maxImg2" src="../assets/img/ly.png" alt="">
                <div class="scope-icon2">
                    <p>旅游行业平台</p>
                </div>
            </div>
            <div class="scope-item2">
                <img class="maxImg2" src="../assets/img/yq.png" alt="">
                <div class="scope-icon2">
                    <p>园区综合体</p>
                </div>
            </div>
            <div class="scope-item2">
                <img class="maxImg2" src="../assets/img/qc.png" alt="">
                <div class="scope-icon2">
                    <p>汽车后市场</p>
                </div>
            </div>
        </div>
        <!-- 服务优势 -->
        <div class="advantages">
            <div class="wedo" style="margin-bottom: 60rem;margin-top: 0rem;">服务优势</div>
            
            <div class="service">
            <div class="service-list" v-if="homeData[2]">
                <div class="service-item-left">
                <div class="service-item" style="margin-left: 60rem;">
                    <div>
                    <p class="service-p1">{{homeData[2].content[0].name}}</p>
                    <p class="service-p2">{{homeData[2].content[0].intro}}</p>
                    </div>
                    <img style="margin-left: 30rem;" :src="domain+homeData[2].content[0].image" alt="">
                </div>
                <div class="service-item" style="margin-left: 60rem;">
                    <div>
                    <p class="service-p1">{{homeData[2].content[1].name}}</p>
                    <p class="service-p2">{{homeData[2].content[1].intro}}</p>
                    </div>
                    <img style="margin-left: 30rem;" :src="domain+homeData[2].content[1].image" alt="">
                </div>
                <div class="service-item" style="margin-left: 60rem;">
                    <div>
                    <p class="service-p1">{{homeData[2].content[2].name}}</p>
                    <p class="service-p2">{{homeData[2].content[2].intro}}</p>
                    </div>
                    <img style="margin-left: 30rem;" :src="domain+homeData[2].content[2].image" alt="">
                </div>
                </div>
                <div class="service-item-center">
                <div >
                    <p>专业技术</p>
                    <p>助力企业</p>
                </div>
                </div>
                <div class="service-item-right">
                <div class="service-item service-item2" style="margin-left: 60rem;">
                    <img style="margin-left: 30rem;margin-right: 30rem;" :src="domain+homeData[2].content[3].image" alt="">
                    <div>
                    <p class="service-p1">{{homeData[2].content[3].name}}</p>
                    <p class="service-p2">{{homeData[2].content[3].intro}}</p>
                    </div>
                </div>
                <div class="service-item service-item2" style="margin-left: 60rem;">
                    <img style="margin-left: 30rem;margin-right: 30rem;" :src="domain+homeData[2].content[4].image" alt="">
                    <div>
                    <p class="service-p1">{{homeData[2].content[4].name}}</p>
                    <p class="service-p2">{{homeData[2].content[4].intro}}</p>
                    </div>
                </div>
                <div class="service-item service-item2" style="margin-left: 60rem;">
                    <img style="margin-left: 30rem;margin-right: 30rem;" :src="domain+homeData[2].content[5].image" alt="">
                    <div>
                    <p class="service-p1">{{homeData[2].content[5].name}}</p>
                    <p class="service-p2">{{homeData[2].content[5].intro}}</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <!-- 开发流程 -->
        <div class="deve">
            <div class="wedo" style="margin-bottom: 60rem;">开发流程</div>
            <div class="flowPath" v-if="homeData[3]">
            <el-progress :percentage="percentage" color="#2B47F6" define-back-color="#E6E6E6" :show-text="false"></el-progress>
            <div class="flowPathList">
                <div :class="[homeData[3].content.length==index+1?'flowPathItem2':'flowPathItem']" v-for="item,index in homeData[3].content" :key="index">
                <img :src="domain+item.image" alt="">
                <p :class="[index%2==0?'flowPathItem-p1':'flowPathItem-p1 flowPathItem-p1-2']">{{item.name}}</p>
                <p class="flowPathItem-p2">{{item.intro}}</p>
                </div>
            </div>
            </div>
        </div>
        <!-- 服务案例 -->
        <div class="advantages2">
            <div class="wedo" style="margin-bottom: 60rem;margin-top: 0rem;">服务案例</div>
            <div class="advantlist">
                <div class="advantitem" v-for="item,index in softwareinfo1" :key="index">
                    <img :src="domain+item.image" alt="">
                    <div class="advaText">
                        <p class="advaText-p1">{{item.name}}</p>
                        <p class="advaText-p2">{{item.label}}</p>
                    </div>
                </div>
            </div>
        </div>
        <FootBottom></FootBottom>
        <FootBottom2 :tabNum="tabNum"></FootBottom2>
    </div>
</template>

<script>
import HeaderTop from '@/components/HeaderTop.vue'
import FootBottom from '@/components/FootBottom.vue'
import FootBottom2 from '@/components/FootBottom2.vue'
import { getSkillDetailByType,getProductDetail,getIndexData,getAdvByType } from '@/assets/js/store.js'
export default {
    data () {
        return {
            // 域名
            domain:'',
            tabNum:2,
            isShow:false,
            // 技能详情
            softwareinfo1:[],
            // 技能详情type类型
            typeif:0,
            // 展示名称
            isName:'',
            // 行业方案下的tab切换
            tas:0,
            // 阴影
            hyfa:false,
            // 走马灯切换一
            carouselIndex1:0,
            // 走马灯下一张展示
            carouselUrl:'',
            // 产品详情
            softwareinfo2:{},
            // 产品详情id
            pid:'',
            // 产品案例显示隐藏
            isDuoka2:false,
            showRefresh: false,  // 标记是否需要刷新页面
            // 首页数据
            homeData:{},
            activeName:'',
            // 合作伙伴的图片
            hzhbImg1:[],
            hzhbImg2:[],
            // 进度条
            percentage:0,
        };
    },

    components: {
        HeaderTop,
        FootBottom,
        FootBottom2
    },

    computed: {},
    created(){
        
    },
    mounted(){
        this.pid = this.$route.query.id
        // 判断技能详情(1)接口还是产品详情接口数据(2)
        // if(this.$route.query.type==1){
            this.isName = this.$route.query.names
            if(this.isName == '小程序定制开发'){
                this.typeif = 1
                this.getgetSkillDetailByType()
            }else if(this.isName == 'APP开发'){
                this.typeif = 2
                this.getgetSkillDetailByType()
            }else if(this.isName == 'H5/网站开发'){
                this.typeif = 3
                this.getgetSkillDetailByType()
            }else if(this.isName == '物联网/人工智能'){
                this.typeif = 4
                this.getgetSkillDetailByType()
            }else if(this.isName == '大数据/系统集成'){
                this.typeif = 5
                this.getgetSkillDetailByType()
            }else if(this.isName == '企业服务'){
                this.typeif = 6
                this.getgetSkillDetailByType()
            }
        // }else{
            // this.getgetProductDetail()
            // this.isShow = true
        // }
        this.getgetIndexData()
        this.getgetAdvByType()
        setInterval(()=>{
        this.percentage++
        if(this.percentage==100){
            this.percentage = 0
            }
        },100)
    },
    methods: {
        btn1(){
            //document.getElementById("mini-btn").click();
      document.getElementsByClassName("embed-icon-default")[0].click()
        },
        // 根据类型获取广告（顶部Banner）
    async getgetAdvByType(){
      const res = await getAdvByType({type:1})
      if(res.data.code == 1){
        this.domain = res.data.data.headpath
        // let pageHeight = window.getComputedStyle(this.$refs.home).height; 
        // console.log('123456',pageHeight);
      }else{
        this.$message({
          showClose: true,
          message: res.data.msg,
          duration:3000,
          type: 'error'
        });
      }
    },
        // 获取首页数据
    async getgetIndexData(cid){
      const res = await getIndexData({cid:cid})
      if(res.data.code == 1){
        this.homeData = res.data.data
        if(cid==undefined){
          this.activeName = this.homeData[4].content.caseclass[0].name
        }else{
          this.activeName = this.homeData[4].content.caseclass[cid].name
          console.log(this.activeName,cid);
        }
        this.hzhbImg1=[]
        this.hzhbImg2=[]
        res.data.data[6].content.forEach((item,index) => {
          if(index<=12){
            this.hzhbImg1.push(item.image)
          }else{
            this.hzhbImg2.push(item.image)
          }
        });
        res.data.data[6].content.forEach((item,index) => {
          if(index<=12){
            this.hzhbImg1.push(item.image)
          }else{
            this.hzhbImg2.push(item.image)
          }
        });
      }else{
        this.$message({
          showClose: true,
          message: res.data.msg,
          duration:3000,
          type: 'error'
        });
      }
    },
        // 获取技能详情页数据（小程序等）
        async getgetSkillDetailByType(){
            const res = await getSkillDetailByType({type:this.typeif})
            if(res.data.code == 1){
                // this.domain = res.data.data.headpath
                this.softwareinfo1 = res.data.data
                // if(this.softwareinfo1.case.length==this.carouselIndex1+1){
                //     this.carouselUrl = ''
                // }else{
                //     this.carouselUrl = this.softwareinfo1.case[this.carouselIndex1+1].showimage
                // }
                
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        // 获取技能详情页数据（小程序等）
        async getgetProductDetail(){
            const res = await getProductDetail({pid:this.pid})
            if(res.data.code == 1){
                this.domain = res.data.data.headpath
                this.softwareinfo2 = res.data.data
                if(this.softwareinfo2.cases.length==this.carouselIndex1+1){
                    this.carouselUrl = ''
                }else{
                    this.carouselUrl = this.softwareinfo2.case[this.carouselIndex1+1].showimage
                }
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        // 行业方案的阴影
        // mouseenter(){
        //     this.hyfa = true
        // },
        // mouseleave(){
        //     this.hyfa = false
        // },
        // change(e,x){
        //     // e 目前激活的幻灯片的索引，x 原幻灯片的索引
        //     this.carouselIndex1 = e
        //     if(this.isShow==false){
        //         if(this.softwareinfo1.case.length==e+1){
        //             this.carouselUrl = ''
        //         }else{
        //             this.carouselUrl = this.softwareinfo1.case[e+1].showimage
        //         }
        //     }else{
        //         if(this.softwareinfo2.cases.length==e+1){
        //             this.carouselUrl = ''
        //         }else{
        //             this.carouselUrl = this.softwareinfo2.cases[e+1].showimage
        //         }
        //     }
            
        // },
        setActiveItem(isShow){
            if(isShow==false){
                if(this.softwareinfo1.case.length==this.carouselIndex1+1){
                    this.$refs.carousel.setActiveItem(0)
                }else{
                    this.$refs.carousel.setActiveItem(this.carouselIndex1+1)
                }
            }else{
                if(this.softwareinfo2.cases.length==this.carouselIndex1+1){
                    this.$refs.carousel.setActiveItem(0)
                }else{
                    this.$refs.carousel.setActiveItem(this.carouselIndex1+1)
                }
            }
        },
        aaa(){
            this.$router.push('/cases')
        }
    }
}

</script>
<style lang='less' scoped>
@import '../assets/css/software.css';
@import '../assets/css/home.css';
</style>