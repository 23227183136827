import Vue from 'vue'
import VueRouter from 'vue-router'
// pc端
import Home from '../views/Home.vue'
import information from '../views/information.vue'
import success from '../views/success.vue'
import software from '../views/software.vue'
import cases from '../views/case.vue'
// 移动端
import ydIndex from '../views/yd_index.vue'
import ydAbout from '../views/yd_about.vue'
import ydInformation from '../views/yd_information.vue'
import ydSuccess from '../views/yd_success.vue'
import ydSoftware from '../views/yd_software.vue'

Vue.use(VueRouter)

const routes = [
  // pc
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/information',
    name: 'information',
    component: information
  },
  {
    path: '/success',
    name: 'success',
    component: success
  },
  {
    path: '/software',
    name: 'software',
    component: software
  },
  {
    path: '/cases',
    name: 'cases',
    component: cases
  },
  // 移动
  {
    path: '/ydIndex',
    name: 'ydIndex',
    component: ydIndex
  },
  {
    path: '/wap/ydIndex',
    name: 'ydIndex',
    component: ydIndex
  },
  {
    path: '/ydAbout',
    name: 'ydAbout',
    component: ydAbout
  },
  {
    path: '/ydInformation',
    name: 'ydInformation',
    component: ydInformation
  },
  {
    path: '/ydSuccess',
    name: 'ydSuccess',
    component: ydSuccess
  },
  {
    path: '/ydSoftware',
    name: 'ydSoftware',
    component: ydSoftware
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior:(to, from, savedPosition) => {
    return {x:0,y:0}
  }
})



export default router
