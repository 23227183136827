<!--  -->
<template>
    <!-- <div class="ydfoot" :style="{ 'background-image':'url('+domain+logoImg+')'}"> -->
    <div class="ydfoot">
        <p>一站式软件开发</p>
        <p>你身边的软件工程专家！</p>
        <div class="ydfoot_zx">
            <p>马上咨询</p>
            <img src="../assets/yd_img/yjt2.png" alt="">
        </div>
    </div>
</template>

<script>
import { getCommonData } from '@/assets/js/store.js'
export default {
    data () {
        return {
            // 域名
            domain:'',
            // logo图片
            logoImg:''
        };
    },

    components: {},

    computed: {},
    mounted(){
        this.getgetCommonData()
    },

    methods: {
        // 获取公共部分信息
        async getgetCommonData(){
            const res = await getCommonData()
            if(res.data.code == 1){
                this.logoImg = res.data.data.slogn
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
            
        },
    }
}

</script>
<style lang='less' scoped>
.ydfoot{
    width: 100%;
    height: 1.72rem;
    background-image: url(../assets/img/zx.png);
    background-position: 67% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 600;
    font-size: 0.23rem;
    color: #FFFFFF;
    padding: 0.25rem 0 0 0.3rem;
    box-sizing: border-box;
}
.ydfoot_zx{
    width: 1.07rem;
    height: 0.35rem;
    background: #FFFFFF;
    border-radius: 0.02rem;
    font-weight: 600;
    font-size: 0.12rem;
    color: #2B47F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.14rem;
    box-sizing: border-box;
    margin-top: 0.17rem;
}
.ydfoot_zx img{
    width: 0.14rem;
    height: 0.08rem;
}
</style>