<template>
  <div class="header-top2">
    <!-- <div class="tab" :style="{background:activeColor}" v-if="activeColor=='none'">
      <div class="tab-logo" @click="home">
            <img :src="domain+logoImg" alt="正在加载">
            <p>多咖科技</p>
      </div>
      <ul class="tab-list">
        <li v-for="a,b in tabList" :key="b" :class="tabNum==b+1?'bai':''" @click="tabs(a)" @mouseenter="mouseenter(a,b)" @mouseleave="mouseleave(a,b)">{{a.name}}</li>
      </ul>
    </div> -->
    <div class="tab" :style="{borderBottom:'1px solid #E6E6E6'}">
      <div class="tab-logo tab-logo2" @click="home">
            <img :src="domain+logoImg" alt="正在加载" @click="logos">
            <!-- <p>多咖科技</p> -->
      </div>
      <ul class="tab-list tab-list2">
        <li v-for="a,b in tabList" :key="b" :class="tabNum==b+1?'bai':''" @click="tabs(a)" @mouseenter="mouseenter(a,b)" @mouseleave="mouseleave(a,b)">{{a.name}}</li>
      </ul>
    </div>
      <transition name="el-fade-in">
        <!-- v-show="isShow" -->
        <div class="solveProgramme" v-show="false"  @mouseenter="mouseenter2" @mouseleave="mouseleave2">
          <div class="solve-box">
            <div class="solve-box1">
                <div style="margin-bottom: 36rem;cursor:pointer;"  v-for="item,index in sonList">
                  <p class="solve-p1" @click="tabSon(index)">{{item.name}}</p>
                  <img v-show="sonListIndex == index" class="img-lan" src="../assets/img/lan.png" alt="">
                </div>
                <p class="solve-p2">没有找到解决方案？马上联系我们</p>
                <p class="solve-p1" style="margin-top: 5rem;">{{phone}}</p>
            </div>
            <div class="solve-box2">
              <p class="solve-p1" v-if="sonList[sonListIndex]">{{sonList[sonListIndex].name}}</p>
              <div class="solve-box3" v-if="sonList[sonListIndex]">
                <div class="solve-item"  v-for="item,index in sonList[sonListIndex].cid" @click.stop="cidUrl(item)">
                  <img :src="domain+item.image" alt="">
                  <div class="solve-p">
                    <p class="solve-item-p1">{{item.name}}</p>
                    <p class="solve-item-p2">{{item.note}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
  </div>
</template>

<script>
import { getCommonData } from '@/assets/js/store.js'

import { nextTick } from 'vue';
export default {
  name: 'HeaderTop',
  data() {
			return {
        // 域名
        domain:'',
        // tab列表
				tabList:[],
        // 悬浮弹窗是否显示
        isShow:false,
        // 解决方案的子数组内容
        sonList:[],
        // 解决方案的子数组内容的索引值
        sonListIndex:0,
        // logo图片
        logoImg:'',
        phone:'',
        activeColor:"none"
      }
	},
  props: {
    tabNum: Number
  },
  mounted(){
    this.getgetCommonData()
    // 添加滚动事件监听器
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    handleScroll() {
      // console.log(window.scrollY);
      // 处理滚动事件
      if(window.scrollY>=900){
        this.activeColor = '#FFFFFF' 
      }else{
        this.activeColor = 'none' 
      }
    },
    // {text:'解决方案',url:''},{text:'软件产品',url:'/software'},{text:'成功案例',url:'/success'},{text:'资讯动态',url:'/information'},{text:'关于我们',url:'/about'}
    // 获取公共部分信息
    async getgetCommonData(){
        const res = await getCommonData()
        if(res.data.code == 1){
          this.tabList = res.data.data.topcolumn
          this.logoImg = res.data.data.logo
          this.domain = res.data.data.headpath
          this.phone = res.data.data.contact
          // this.tabList[0].son.forEach((item,index) => {
          //   this.sonList.push(item)
          // })
        }else{
          this.$message({
            showClose: true,
            message: res.data.msg,
            duration:3000,
            type: 'error'
          });
        }
        
    },
    // 子内容切换
    tabSon(index){
      this.sonListIndex = index
    },
    cidUrl(item){
      // console.log(item);
      // window.location.href.href = item.url
    },
    home(){
      // this.$router.push('/')
    },
    logos(){
      this.$router.push('/')
    },
    tabs(a){
      nextTick(() => {
        // console.log(window.pageYOffset);
        console.log(a.url);
        if(a.url == ''){
          if(this.tabNum!=0){
            this.$router.push('/')
          }else{
            const element = document.getElementById("wedo1");
            element.scrollIntoView({ behavior: "smooth" });
          }
        }else if(a.url == '/success'){
          if(this.tabNum!=0){
            this.$router.push('/')
          }else{
            const element = document.getElementById("wedo2");
            element.scrollIntoView({ behavior: "smooth" });
          }
        }else if(a.url == '/information'){
          this.$router.push(a.url)
          // if(this.tabNum!=0){
          //   this.$router.push(a.url)
          // }else{
          //   const element = document.getElementById("wedo3");
          //   element.scrollIntoView({ behavior: "smooth" });
          //   element.scrollIntoView({ behavior: "smooth" });
          // }
        }else if(a.url == '/about'){
          this.$router.push(a.url)
        }else if(a.url == '/software'){
          this.$router.push({path:'/software',query: {id: 1,type: 1,names:'小程序定制开发'}})
        }
        // if(a.url == ''){
        //   return
        // }else if(a.url == '/software'){
        //   this.$router.push({path:'/software',query: {id: 1,type: 1,names:'小程序开发'}})
        // }else{
        //   this.$router.push(a.url)
        // }
      
      })
    },
    mouseenter(a,b){
      this.sonList = []
      this.sonListIndex = 0
      this.tabList[b].son.forEach((item,index) => {
        this.sonList.push(item)
      })
      if(b == 0){
        this.isShow = true
        // this.tabNum = 1
      }
    },
    mouseleave(a,b){
      if(b == 0){
          // this.tabNum = 0
          this.isShow = false
      }
    },
    mouseenter2(){
      this.isShow = true
      // this.tabNum = 1
    },
    mouseleave2(){
      // this.tabNum = 0
      this.isShow = false
    }
  }
}
</script>


<style scoped>

.header-top2{
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
}
.tab{
  width: 100%;
  height: 89rem;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  padding-left: 170rem;
  /* box-sizing: border-box; */
  
}
.tab-logo{
  display: flex;
  align-items: center;
  cursor:pointer;
}
.tab-logo img{
  width: 139rem;
  height: 44rem;
}
.tab-logo p{
  font-size: 20rem;
  color: #FFFFFF;
  margin-left: 8rem;
}
.tab-logo2 p{
  color: #000000;
}
.tab-list{
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16rem;
  color: #FFFFFF;
  margin-left: 316rem;
}
.tab-list2{
  color: #000000;
  
}
.tab-list li{
  margin-right: 92rem;
  cursor:pointer;
  opacity: 0.8;
  list-style: none;
}
.tab-list li:hover{
  opacity: 1 !important;
  color: #FFFFFF;
}
.tab-list2 li:hover{
  opacity: 1 !important;
  font-weight: 600;
  color: #2B47F6;
}
.bai{
  opacity: 1 !important;
  color: #2B47F6;
}
.solveProgramme{
  width: 100%;
  height: 661rem;
  background: #000000;
  position: absolute;
  padding-top: 73rem;
  box-sizing: border-box;
  z-index: 999;
}
.solve-box{
  padding-left: 320rem;
  box-sizing: border-box;
  display: flex;
}
.solve-box1{
  width: 300rem;
  height: 485rem;
  border-right: 1rem solid #979797;
}
.solve-p1{
  font-weight: 400;
  font-size: 18rem;
  color: rgba(255, 255, 255, 0.8);
}
.solve-p1:hover{
  color: #FFFFFF;
}
.img-lan{
    width: 108rem;
    height: 3rem;
    margin-top: 5rem;
}
.solve-p2{
    font-weight: 400;
    font-size: 12rem;
    color: #FFFFFF;
    opacity: 0.8;
    margin-top: 275rem;
}
.solve-box2{
  flex: 1;
  padding-left: 66rem;
  box-sizing: border-box;
}
.solve-box3{
  width: 794rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor:pointer;
}
.solve-item{
  display: flex;
  margin-top: 40rem;
}
.solve-item img{
    width: 32rem;
    height: 32rem;
}
.solve-p{
  padding-left: 12rem;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
}
.solve-item-p1{
  font-weight: 400;
  font-size: 16rem;
}
.solve-item-p2{
  width: 279rem;
  font-weight: 400;
  font-size: 14rem;
  margin-top: 20rem;
}
.solve-p:hover{
  color: #FFFFFF;
}
</style>
