<!-- 成功案例 yd_success -->
<template>
    <div>
        <div class="yd_about_header">
            <ydHeaderTop></ydHeaderTop>
            <div class="rsdks">认识多咖</div>
            <p class="ydHeader_p1">精选案例</p>
            <p class="ydHeader_p2">
                多种类跨行业实战经验
                <img class="router1" src="../assets/yd_img/route1.png" alt="">
            </p>
            <div class="herder_btn">
                <div class="tjwx">添加微信</div>
                <div class="mszx">
                    马上咨询
                    <img class="mszx_img" src="../assets/yd_img/yjt1.png" alt="">
                </div>
            </div>
        </div>
        <div style="background: #F4F5F8;">
            <div class="success_tap">
                <div class="case_tab2" :style="{'width':tabList.length*(1.1)+'rem'}">
                    <div  :class="tabIndex==index?'case_tab_item case_tab_item2':'case_tab_item'" @click="tabIndex1(item,index)" v-for="item,index in tabList" :key="index">
                        {{item.name}}
                    </div>
                    
                </div>
            </div>
            <div class="success_const" v-for="item,index in caseData" :key="index">
                <div class="success_title">
                    {{item.name}}
                </div>
                <div class="success_laber">
                    <div class="_laber" v-for="items,indexs in caseData.label" :key="indexs">
                        {{items}}
                    </div>
                </div>
                <p class="success_txt" v-html="item.content"></p>
                <div class="maximgs maximgs2" :style="{ 'background-image':'url('+domain+item.showimage+')'}">
                    <img class="success_ewm" :src="domain+item.code" alt="">
                </div>
            </div>
        </div>
        <ydFootBottom2></ydFootBottom2>
    </div>
</template>

<script>
import ydHeaderTop from '@/components/ydHeaderTop.vue'
import ydFootBottom from '@/components/ydFootBottom.vue'
import ydFootBottom2 from '@/components/ydFootBottom2.vue'
import { getSuccessCaseData } from '@/assets/js/store.js'
export default {
    data () {
        return {
            // 域名
            domain:'',
            // 标题标签
            tabList:[],
            tabIndex:0,
            caseData:[]
        };
    },

    components: {
        ydHeaderTop,
        ydFootBottom,
        ydFootBottom2
    },

    computed: {},

    mounted(){
        if(this.$route.query.id){
            this.tabIndex = this.$route.query.id-1
            this.getgetSuccessCaseData(this.$route.query.id)
        }else{
            this.getgetSuccessCaseData()
        }
        
    },

    methods: {
         // 根据类型获取成功案例数据
        async getgetSuccessCaseData(cid){
            const res = await getSuccessCaseData({cid:cid})
            if(res.data.code == 1){
                this.tabList = res.data.data.caseclass
                // this.successData = res.data.data
                this.caseData = res.data.data.case
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        // tab切换
        tabIndex1(item,index){
            this.tabIndex = index
            this.getgetSuccessCaseData(index+1)
        }
    }
}

</script>
<style lang='less' scoped>
@import '../assets/css/yd_success.css';
</style>