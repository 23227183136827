<!-- 移动端头部组件 -->
<template>
    <div>
        <div class="yd_harder">
            <div class="yd_harder_left" @click="$router.push('/')">
                <img :src="domain+logoImg" alt="">
                <!-- <p>多咖科技</p> -->
            </div>
            <img class="yd_harder_right" @click="isShow=true" src="https://cdn4.codesign.qq.com/screen-slices/2024/05/31/wKrMZ24eXQ3V050k1md31/dog6yvomgbqzl2gi/045162f0-7c46-4781-bea9-5a72a644d3fa.png?imageMogr2/thumbnail/700x650/interlace/1" />
        </div>
        <div style="height: 0.6rem;"></div>
        <div class="boxList" v-show="isShow">
            <ul class="ydFootBottomUL">
                <li class="">
                    <img @click="$router.push('/'),isShow=false" class="liImg1" :src="domain+logoImg" alt="">
                    <img @click="isShow=false" class="liImg2" src="https://cdn4.codesign.qq.com/screen-slices/2024/05/31/wKrMZ24eXQ3V050k1md31/dog6yvomgbqzl2gi/da643e4e-cb9d-4983-aaf3-143d25c0b9ab.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                </li>
                <li @click="tabs(a)" v-for="a,b in tabList" :key="b">
                    <p>{{a.name}}</p>
                    <img src="../assets/yd_img/liy.png" alt="">
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { getCommonData } from '@/assets/js/store.js'
import { nextTick } from 'vue';
export default {
    data () {
        return {
            // 域名
            domain:'',
            // tab列表
			tabList:[],
            // logo图片
            logoImg:'',
            isShow:false
        };
    },
    props: {
        tabNum: Number
    },
    components: {},

    computed: {},

    mounted(){
        this.getgetCommonData()
    },

    methods: {
        async getgetCommonData(){
            const res = await getCommonData()
            if(res.data.code == 1){
                this.tabList = res.data.data.topcolumn
                this.logoImg = res.data.data.logo
                this.domain = res.data.data.headpath
            }else{
            this.$message({
                showClose: true,
                message: res.data.msg,
                duration:3000,
                type: 'error'
            });
            }
            
        },
        tabs(a){
            nextTick(() => {
                this.isShow = false
                console.log(a.url);
                if(a.url == ''){
                    if(this.tabNum!=0){
                        this.$router.push('/')
                    }else{
                        const element = document.getElementById("wedo1");
                        element.scrollIntoView({ behavior: "smooth" });
                    }
                }else if(a.url == '/success'){
                    if(this.tabNum!=0){
                        this.$router.push('/')
                    }else{
                        const element = document.getElementById("wedo2");
                        element.scrollIntoView({ behavior: "smooth" });
                    }
                }else if(a.url == '/information'){
                    this.$router.push({path:'/ydInformation',query: {type: 1}})
                    // if(this.tabNum!=0){
                    //   this.$router.push(a.url)
                    // }else{
                    //   const element = document.getElementById("wedo3");
                    //   element.scrollIntoView({ behavior: "smooth" });
                    //   element.scrollIntoView({ behavior: "smooth" });
                    // }
                }else if(a.url == '/about'){
                    this.$router.push('/ydAbout')
                }else if(a.url == '/software'){
                    this.$router.push({path:'/ydSoftware',query: {id: a.id,type: 1,names:a.name}})
                }
            })
        },
    }
}

</script>
<style lang='less' scoped>
.yd_harder{
    width: 100%;
    height: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.3rem;
    box-sizing: border-box;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    z-index: 8;
    .yd_harder_left{
        display: flex;
        align-items: center;
        img{
            width: 1rem;
            height: 0.31rem;
        }
        p{
            font-weight: NanShen;
            font-size: 0.15rem;
            color: #000000;
            margin-left: 0.1rem;
        }
    }
    .yd_harder_right{
        width: 0.3rem;
        height: 0.3rem;
    }
}
.boxList{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    z-index: 99;
}
.ydFootBottomUL{
    // padding: 0 0.16rem;
    // box-sizing: border-box;
}
.ydFootBottomUL li{
    width: 100%;
    height: 0.63rem;
    border-bottom: 0.01rem solid #DBDBDB;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 0.15rem;
    color: #222222;
    padding: 0 0.16rem;
    box-sizing: border-box;
}
.ydFootBottomUL img{
    width: 0.08rem;
    height: 0.13rem;
}
.liImg1{
    width: 1rem !important;
    height: 0.31rem !important;
}
.liImg2{
    width: 0.26rem !important;
    height: 0.26rem !important;
}
</style>