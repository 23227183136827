<template>
    <div class="fip">
        <div :class="[isNum?'num':'']">
            <div v-for="item,index in arr" :key="index">
                {{year=='年'?item+'年':item}}
            </div>
            
        </div>
    </div>
</template>
 
<script>
export default {
  name: 'MarqueeText',
  props: {
    maxNumber: {
      type: Number,
      default: 9,
    },
    num: {
      type: Number,
    },
    year: {
      type: String,
    },
  },
  data() {
    return {
      timer: null,
      arr:[0,2,3,4,5],
      isNum:false
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted(){
      let isShu = 0
      this.timer = setInterval(()=>{
          isShu++
          this.isNum = true
          if(isShu == 2){
            this.isNum = false
            clearInterval(this.timer);
            this.arr = [this.num]
        }
      },(this.maxNumber+'00'))
  }
};
</script>
 
<style scoped>
.fip{
    height: 63rem;
    font-weight: 600;
    font-size: 45rem;
    color: #000000;
    overflow: hidden;
}
.num{
    animation: scroll .5s linear infinite;
}
@keyframes scroll {
  0% {
      margin-top: 0rem;
  }
  20%{
      margin-top: -50rem;
  }
  40%{
      margin-top: -100rem;
  }
  60%{
      margin-top: -150rem;
  }
  80%{
      margin-top: -200rem;
  }
  100% {
      margin-top: -250rem;
  }
}
/* .marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 32rem;
}
 
.marquee-text {
  display: inline-block;
  animation-name: scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}
 
@keyframes scroll {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
} */
</style>