<!-- information -->
<template>
    <div>
        <!-- 头部 -->
        <div class="header-top8">
            <HeaderTop :tabNum="tabNum"></HeaderTop>
            <p class="header-top8-p1">探索行业</p>
            <p class="header-top8-p2">知识分享与新闻前沿</p>
        </div>
        <!-- 局部左右边距320版新 -->
        <div class="box-320" v-if="isShow">
            <p class="p-title"><span @click="$router.push('/')">首页</span> / <span @click="isShow = false">资讯动态</span> / <span style="color:#333333;">资讯详情</span></p>
            <div class="dynamicdetails">
                <div class="dynamic-left">
                    <p class="dynamic-title">{{neswinfo.new.name}}</p>
                    <p class="tiem">{{neswinfo.new.showtime}}</p>
                    <div class="bor"></div>
                    <p class="fwb" v-html="neswinfo.new.content"></p>
                </div>
                <div class="dynamic-right">
                    <p class="dynamic-title" style="font-size: 20rem;">多咖推荐</p>
                    <div class="bor"></div>
                    <div style="margin-bottom:20rem;" v-for="item,index in neswinfo.recommend" @click="Morenews(item.id)">
                        <img class="fwb-img2" :src="domain+item.image" alt="">
                        <p class="dynamic-title" style="font-size: 20rem; margin-top:10rem;">{{item.name}}</p>
                    </div>
                </div>
                
            </div>
            <div style="height:30rem;"></div>
        </div>
        <div v-else >
            <!-- <div class="industry" v-if="list1.news">
                <div class="industry-item" v-for="item,index in list1.news.data":key="index" @click="getgetNewsDetail(item.id)">
                    <img :src="domain+item.image" alt="">
                    <div class="news_const">
                        <p class="news_const-p1">{{item.name}}</p>
                        <p class="news_const-p2">{{item.intro}}</p>
                        <p class="news_const-p3">{{item.showtime.substring(5,10)}}</p>
                        <div class="news_const-btm">
                        <p class="news_const-p4">{{item.showtime.substring(0,4)}}</p>
                        <img src="../assets/img/yjt_h.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                    background
                    @current-change="handleCurrentChange1"
                    :current-page="page1"
                    layout="prev, pager, next"
                    :total="total1">
                    </el-pagination>
                </div>
            </div> -->
            <div class="news-list" v-if="list2.news">
                <div class="news-item2" v-for="item,index in list2.news.data":key="index" @click="getgetNewsDetail(item.id)">
                    <div class="news-item2-left">
                        <img class="nesImg" :src="domain+item.image" alt="">
                        
                    </div>
                    <div class="news-item2-right" >
                        <div>
                            <p class="news-item2-p3">{{item.name}}</p>
                            <p class="news-item2-p4">{{item.intro}}</p>
                        </div>
                        <div>
                            <p class="news-item2-p1">{{item.showtime.substring(5,10)}}</p>
                            <div class="news-item2-right-btom">
                                <p class="news-item2-p2">{{item.showtime.substring(0,4)}}</p>
                                <img class="yjt_h" src="../assets/img/yjt_h.png" alt="">
                                <img class="yjt2" src="../assets/img/yjt2.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination">
                    <el-pagination
                    background
                    @current-change="handleCurrentChange2"
                    :current-page="page2"
                    layout="prev, pager, next"
                    :total="total2">
                    </el-pagination>
                </div>
            <div style="height:100rem;"></div>
        </div>
    <FootBottom2 :tabNum="tabNum"></FootBottom2>
    </div>
</template>

<script>
import HeaderTop from '@/components/HeaderTop.vue'
import FootBottom2 from '@/components/FootBottom2.vue'
import { getNewsList,getNewsDetail } from '@/assets/js/store.js'
export default {
    data () {
        return {
            // 域名
            domain:'',
            tabNum:4,
            isShow:false,
            list1:{},
            page1:1,
            total1:0,
            list2:{},
            page2:1,
            total2:0,
            neswinfo:{}
        };
    },
    components: {
        HeaderTop,
        FootBottom2
    },
    computed: {},
    mounted(){
        this.getgetNewsList(1,{type:1,page:this.page1})
        this.getgetNewsList(2,{type:2,page:this.page2})
        if(this.$route.query.id){
            this.getgetNewsDetail(this.$route.query.id)
        }
    },
    methods: {
        // 资讯详情跳转2
        Morenews(id){
            // this.$router.push({path:'/information',query: {id:id}})
            this.getgetNewsDetail(id)
        },
        // 获取新闻列表数据（分页）
        async getgetNewsList(x,obj){
            const res = await getNewsList(obj)
            if(res.data.code == 1){
                if(x==1){
                    this.list1 = res.data.data
                    this.total1 = res.data.data.news.total
                }else{
                    this.list2 = res.data.data
                    this.total2 = res.data.data.news.total
                }
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        // 行业动态分页切换
        handleCurrentChange1(val){
            this.page1 = val
            this.getgetNewsList(1,{type:1,page:this.page1})
        },
        // 行业知识普及分页切换
        handleCurrentChange2(val){
            this.page2 = val
            this.getgetNewsList(2,{type:2,page:this.page2})
        },
        // 获取新闻详情（带推荐）
        async getgetNewsDetail(id){
            const res = await getNewsDetail({nid:id})
            if(res.data.code == 1){
                this.isShow = true
                this.neswinfo = res.data.data
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
    }
}

</script>
<style lang='less' scoped>
@import '../assets/css/information.css';
</style>