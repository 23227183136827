// 导入并配置axios
import axios from "axios";
//配置请求的根地址
axios.defaults.baseURL = "http://develop.www.duoka361.cn"; 
// 获取公共部分信息
export const getCommonData = () => {
    return axios({
        method: 'get',
        url: '/getCommonData'
        // headers: {
        //     'content-type':'application/x-www-form-urlencoded'
        // }
    });
}
// 根据类型获取广告（顶部Banner）
export const getAdvByType = (parms) => {
    return axios({
        method: 'get',
        url: '/getAdvByType',
        params:parms
    });
}
// 获取首页数据
export const getIndexData = (parms) => {
    return axios({
        method: 'get',
        url: '/getIndexData',
        params:parms
    });
}
// 获取关于我们页数据
export const getAboutusData = () => {
    return axios({
        method: 'get',
        url: '/getAboutusData'
    });
}
// 获取新闻列表数据（分页）
export const getNewsList = (parms) => {
    return axios({
        method: 'get',
        url: '/getNewsList',
        params:parms
    });
}
// 获取新闻详情（带推荐）
export const getNewsDetail = (parms) => {
    return axios({
        method: 'get',
        url: '/getNewsDetail',
        params:parms
    });
}
// 获取成功案例数据
export const getSuccessCaseData = (parms) => {
    return axios({
        method: 'get',
        url: '/getSuccessCaseData',
        params:parms
    });
}
// 获取技能详情页数据（小程序等）
// getSkillDetailByType
export const getSkillDetailByType = (parms) => {
    return axios({
        method: 'get',
        url: '/getCaseByType',
        params:parms
    });
}
// 获取产品详情数据
export const getProductDetail = (parms) => {
    return axios({
        method: 'get',
        url: '/getProductDetail',
        params:parms
    });
}