<!--  -->
<template>
    <div>
        <ydHeaderTop></ydHeaderTop>
        <div class="yd_about_header3">
            <p class="ydHeader_p3" style="margin-bottom: 0.07rem;">
                探索行业
            </p>
            <p class="ydHeader_p4">
                知识分享与新闻前沿
            </p>
        </div>
        <div class="News" v-if="isShow">
            <div class="min_new3" @click="realtime(item)"  v-for="item,index in list2" :key="index">
                <div class="min_new_info">
                    <p class="min_new_p3">{{item.name}}</p>
                    <p class="min_new_p2">{{item.showtime}}</p>
                </div>
                <img :src="domain+item.image" alt="">
            </div>
        </div>
        <div class="nexBox" v-else>
            <p class="nexBoxTitle">{{neswinfo.new.name}}</p>
            <p class="nexBoxTime">{{neswinfo.new.showtime}}</p>
            <div class="lan"></div>
            <p class="content" v-html="neswinfo.new.content"></p>
            <div class="lan2"></div>
            <p class="recommendation">多咖推荐</p>
            <div style="margin-top:0.19rem;">
                <p class="recommendation2"  v-for="item,index in neswinfo.recommend" @click="Morenews(item.id)">{{item.name}}</p>
            </div>
        </div>
        <ydFootBottom></ydFootBottom>
        <ydFootBottom2></ydFootBottom2>
    </div>
</template>

<script>
import ydHeaderTop from '@/components/ydHeaderTop.vue'
import ydFootBottom from '@/components/ydFootBottom.vue'
import ydFootBottom2 from '@/components/ydFootBottom2.vue'
import { getNewsList,getNewsDetail } from '@/assets/js/store.js'
export default {
    data () {
        return {
            isShow:true,
            // 域名
            domain:'',
            list1:{},
            page1:1,
            list2:{},
            page2:1,
            neswinfo:{}
        };
    },

    components: {
        ydHeaderTop,
        ydFootBottom,
        ydFootBottom2
    },

    computed: {},

    mounted(){
        // this.getgetNewsList(1,{type:1,page:this.page1})
        if(this.$route.query.type==1){
            this.getgetNewsList(2,{type:2,page:this.page2})
            this.isShow = true
        }else{
            this.getgetNewsDetail(this.$route.query.id)
            this.isShow = false
        }
    },

    methods: {
        // 获取新闻列表数据（分页）
        async getgetNewsList(x,obj){
            const res = await getNewsList(obj)
            if(res.data.code == 1){
                if(x==1){
                    this.list1 = res.data.data
                    this.total1 = res.data.data.news.total
                }else{
                    this.list2 = res.data.data.news.data
                    this.total2 = res.data.data.news.total
                }
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        // 获取新闻详情（带推荐）
        async getgetNewsDetail(id){
            const res = await getNewsDetail({nid:id})
            if(res.data.code == 1){
                this.isShow = false
                this.neswinfo = res.data.data
            }else{
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    duration:3000,
                    type: 'error'
                });
            }
        },
        Morenews(id){
            // this.$router.push({path:'/information',query: {id:id}})
            this.getgetNewsDetail(id)
        },
        news(x){
            if(x == 1){
                this.isShow1 = true
            }else{
                this.isShow1 = false
            }
        },
        realtime(item){
            this.getgetNewsDetail(item.id)
            this.isShow = false
        }
    }
}

</script>
<style lang='less' scoped>
@import '../assets/css/yd_information.css';
</style>