<!-- 关于我们移动端 yd_about -->
<template>
    <div class="">
        <ydHeaderTop></ydHeaderTop>
        <div class="yd_about_header2">
            <p class="ydHeader_p3" style="margin-bottom: 0.07rem;">
                商业智能技术
            </p>
            <p class="ydHeader_p3">
                开发服务商
            </p>
            <p class="ydHeader_p4">
                您身边的软件技术专家！
            </p>
        </div>
        <div class="padd3-15">
            <!-- 了解多咖 -->
            <p class="aboutTitle">了解多咖</p>
            <p class="aboutTitle2">让您的企业</p>
            <p class="aboutTitle3">成为互联网时代下的鲜明个案</p>
            <img class="MaxImg" src="https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/54a9dbeb-2dd4-4147-b1f9-9384c2f16027.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
            <p class="aboutTitle4">{{aboutData.intro}}</p>
            <p class="aboutTitle5">{{aboutData.productintro}}</p>
            <div class="aboutFun">
                <div class="aboutFunitem">
                    <div class="aboutFunitemTop">
                        <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/298d6872-ca46-4b57-b595-cd01b77c4d31.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                        <p>10年</p>
                    </div>
                    <p class="aboutFunitemP1">深耕软件开发</p>
                </div>
                <div class="aboutFunitem">
                    <div class="aboutFunitemTop">
                        <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/8f91f983-6b61-49b4-b820-75dfa5d9eed4.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                        <p>100%</p>
                    </div>
                    <p class="aboutFunitemP1">交付保障</p>
                </div>
                <div class="aboutFunitem">
                    <div class="aboutFunitemTop">
                        <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/f3d6db96-a159-40b8-80ba-805d40f2bf07.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                        <p>7*24h</p>
                    </div>
                    <p class="aboutFunitemP1">及时响应</p>
                </div>
            </div>
            <div class="aboutFun">
                <div class="aboutFunitem">
                    <div class="aboutFunitemTop">
                        <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/2fdb0e61-1dfc-4faf-8099-569f25193f86.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                        <p>300+</p>
                    </div>
                    <p class="aboutFunitemP1">企业的选择</p>
                </div>
                <div class="aboutFunitem">
                    <div class="aboutFunitemTop">
                        <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/1829c82e-d32b-4119-b2f3-aa3a35dd9670.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
                        <p>500+</p>
                    </div>
                    <p class="aboutFunitemP1">成功案例</p>
                </div>
                <div class="aboutFunitem" style="width:0.7rem">
                    
                </div>
            </div>
            <!-- 合作客户 -->
            <p class="aboutTitle" style="margin-top: 0.77rem;">合作客户</p>
            <div class="Imgs">
                <img v-for="item,index in imgs" :src="item+index+'.png'" alt="">
            </div>
            <!-- 联系我们 -->
            <p class="aboutTitle" style="margin-top: 0.5rem;">联系我们</p>
            <img class="mpaimg" src="https://cdn4.codesign.qq.com/screen-slices/2024/05/28/wKrMZ24eXQ3V050k1md28/upmbwrb9givlebm6/0d3bf11e-0b84-4f47-8ddb-42ca17b8a2de.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
            <p class="aboutTitle">北京 | 总部</p>
            <div class="dkadd">
                <p>E-mail：wjz@duoka361.com</p>
                <p>电话：13716966004</p>
                <p>地址：北京朝阳区通惠河南路1081号环宇国际大厦12层</p>
            </div>
        </div>
        <!-- <ydFootBottom></ydFootBottom> -->
        <ydFootBottom2></ydFootBottom2>
    </div>
</template>

<script>
import ydHeaderTop from '@/components/ydHeaderTop.vue'
import ydFootBottom from '@/components/ydFootBottom.vue'
import ydFootBottom2 from '@/components/ydFootBottom2.vue'
import { getAdvByType,getAboutusData } from '@/assets/js/store.js'
export default {
    data () {
        return {
            // 域名
            domain:'',
            // 头部数据
            headerData:{},
            // 关于我们数据
            aboutData:{},
            lanIndex:0,
            imgs:[
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
                'http://preview.www.duoka361.cn/img/about',
            ],
        };
    },

    components: {
        ydHeaderTop,
        ydFootBottom,
        ydFootBottom2
    },

    computed: {},

    mounted(){
        this.getgetAdvByType()
        this.getgetAboutusData()
    },
    methods: {
        // 根据类型获取广告（顶部Banner）
        async getgetAdvByType(){
        const res = await getAdvByType({type:2})
            if(res.data.code == 1){
                this.headerData = res.data.data
            }else{
                this.$message({
                showClose: true,
                message: res.data.msg,
                duration:3000,
                type: 'error'
                });
            }
        },
        // 获取关于我们页数据
        async getgetAboutusData(){
        const res = await getAboutusData()
            if(res.data.code == 1){
                this.aboutData = res.data.data
                this.domain = res.data.data.headpath
            }else{
                this.$message({
                showClose: true,
                message: res.data.msg,
                duration:3000,
                type: 'error'
                });
            }
        },
        change(e,x){
            this.lanIndex = e
        }
    }
}

</script>
<style lang='less' scoped>
@import '../assets/css/yd_about.css';
</style>