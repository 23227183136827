import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import 'lib-flexible/flexible' // 方案1 方案2
// import "amfe-flexible/index" // 方案3
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/js/rem'
Vue.config.productionTip = false
Vue.use(ElementUI);



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
